import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppInstance } from '../../models/appcloud';
import { ErrorHandlingService } from '../../services/error-handling/error-handling.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { HttpHeaderConstants } from '../../services/http-header.constant';
import { AdvancedSearch, AppInstanceSearch } from '../../models/appcloud/app-instance-search.model';
import { CountResponse } from '../../models/appcloud/count-response';
import { NameCountUtilsService } from './name-count-util.service';

@Injectable({
    providedIn: 'root'
})
export class AppInstanceService {
    private APPINSTANCE_URL: string = environment.publicApiHost + '/appcloud/appinstances';

    constructor(
        private errorHandlingService: ErrorHandlingService,
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private nameCountUtilsService: NameCountUtilsService
    ) {}

    getByGlobalAppId<T>(globalAppId: string, additionalHeaders: any = {}): Observable<AppInstance<T>[]> {
        this.spinnerService.addProcess(
            'AppInstanceService.getByGlobalAppId.' + globalAppId,
            'Loading your app configurations...'
        );
        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams().set('globalAppId', globalAppId).set('deleted', 'false')
        };

        return this.http.get<AppInstance<T>[]>(this.APPINSTANCE_URL, httpOptions).pipe(
            tap((appInstances) => {
                if (appInstances.length > 1) {
                    catchError((error) => {
                        this.errorHandlingService.handleHttpError(
                            error,
                            false,
                            'An error occurred while retrieving configuration data from Relationship One. Please try again later.'
                        );
                        return of<AppInstance<T>[]>();
                    });
                }
            }),
            finalize(() => this.spinnerService.completeProcess('AppInstanceService.getByGlobalAppId.' + globalAppId))
        );
    }

    getByGlobalAppIdAndEloquainstallId<T>(
        globalAppId: string,
        eloquainstallId: number,
        additionalHeaders: any = {}
    ): Observable<AppInstance<T>[]> {
        this.spinnerService.addProcess(
            'AppInstanceService.getByGlobalAppIdAndEloquainstallId.' + eloquainstallId,
            'Loading your app configurations...'
        );
        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
                .set('globalAppId', globalAppId)
                .set('eloquainstallId', eloquainstallId.toString())
                .set('deleted', 'false')
        };

        return this.http.get<AppInstance<T>[]>(this.APPINSTANCE_URL, httpOptions).pipe(
            tap((appInstances) => {
                if (appInstances.length > 1) {
                    catchError((error) => {
                        this.errorHandlingService.handleHttpError(
                            error,
                            false,
                            'An error occurred while retrieving configuration data from Relationship One. Please try again later.'
                        );
                        return of<AppInstance<T>[]>();
                    });
                }
            }),
            finalize(() =>
                this.spinnerService.completeProcess(
                    'AppInstanceService.getByGlobalAppIdAndEloquainstallId.' + eloquainstallId
                )
            )
        );
    }

    getByElqInstanceId<T>(elqInstanceId: string, additionalHeaders: any = {}): Observable<AppInstance<T>[]> {
        this.spinnerService.addProcess(
            'AppInstanceService.getByElqInstanceId.' + elqInstanceId,
            'Loading your app configuration...'
        );

        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams().set('elqInstanceId', elqInstanceId).set('deleted', 'false')
        };

        return this.http.get<AppInstance<T>[]>(this.APPINSTANCE_URL, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving configuration data from Relationship One. Please try again later.'
                );
                return of<AppInstance<T>[]>();
            }),
            finalize(() =>
                this.spinnerService.completeProcess('AppInstanceService.getByElqInstanceId.' + elqInstanceId)
            )
        );
    }

    getByResponsysInstanceUuid<T>(responsysInstanceUuid: string, additionalHeaders: any = {}): Observable<AppInstance<T>[]> {
        this.spinnerService.addProcess(
            'AppInstanceService.getByResponsysInstanceUuid.' + responsysInstanceUuid,
            'Loading your app configuration...'
        );

        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams().set('responsysInstanceDetailUuid', responsysInstanceUuid).set('deleted', 'false')
        };

        return this.http.get<AppInstance<T>[]>(this.APPINSTANCE_URL, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving configuration data from Relationship One. Please try again later.'
                );
                return of<AppInstance<T>[]>();
            }),
            finalize(() =>
                this.spinnerService.completeProcess('AppInstanceService.getByResponsysInstanceUuid.' + responsysInstanceUuid)
            )
        );
    }

    get<T>(id: string, additionalHeaders: any = {}): Observable<AppInstance<T>> {
        this.spinnerService.addProcess('AppInstanceService.get.' + id, 'Loading your app configuration...');

        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders })
        };

        return this.http.get<AppInstance<T>>(this.APPINSTANCE_URL + '/' + id, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving configuration data from Relationship One. Please try again later.'
                );
                return of<AppInstance<T>>();
            }),
            finalize(() => {
                this.spinnerService.completeProcess('AppInstanceService.get.' + id);
            })
        );
    }

    create<T>(newAppinstance: AppInstance<T>, additionalHeaders: any = {}): Observable<AppInstance<T>> {
        this.spinnerService.addProcess('AppInstanceService.create', 'Creating a new configuration...');

        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders })
        };

        return this.http.post<AppInstance<T>>(this.APPINSTANCE_URL, newAppinstance, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to create your configuration. Please try again later.'
                );
                return of<AppInstance<T>>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppInstanceService.create'))
        );
    }

    update<T>(id: number, appInstance: AppInstance<T>, additionalHeaders: any = {}): Observable<AppInstance<T>> {
        this.spinnerService.addProcess('AppInstanceService.update.' + id, 'Updating your configuration...');
        const httpOptions = {
            headers: new HttpHeaders({
                ...HttpHeaderConstants.mergePatch,
                ...additionalHeaders
            })
        };

        return this.http.patch<AppInstance<T>>(this.APPINSTANCE_URL + '/' + id, appInstance, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while sending data to Relationship One. Please try again later.'
                );
                return of<AppInstance<T>>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppInstanceService.update.' + id))
        );
    }

    searchByDataProperty<T>(
        eloquainstallId: number,
        property: string,
        value: string,
        operator?: string,
        additionalHeaders: any = {}
    ): Observable<AppInstance<T>[]> {
        this.spinnerService.addProcess('AppInstanceService.searchByProperty', 'Retrieving configuration(s)...');
        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
                .set('eloquainstallId', eloquainstallId.toString())
                .set('dataSearch[0].property', property)
                .set('dataSearch[0].value', value)
                .set('deleted', 'false')
        };

        if (operator) {
            httpOptions.params = httpOptions.params.set('dataSearch[0].operator', operator);
        }

        return this.http.get<AppInstance<T>[]>(this.APPINSTANCE_URL, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving data from Relationship One. Please try again later.'
                );
                return of<AppInstance<T>[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppInstanceService.searchByProperty'))
        );
    }

    findAll<T>(
        appinstanceSearch: AppInstanceSearch,
        customSpinnerMessage?: string,
        additionalHeaders: any = {}
    ): Observable<AppInstance<T>[]> {
        let spinnerMessage = 'Retrieving your data...';
        if (customSpinnerMessage) {
            spinnerMessage = customSpinnerMessage;
        }
        this.spinnerService.addProcess('AppInstanceService.advancedSearch', spinnerMessage);
        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams()
        };

        for (const [key, value] of Object.entries(appinstanceSearch)) {
            if (key === 'dataSearch' && value.length) {
                this.addDataSearchParams(value, httpOptions);
            } else if (key === 'nameSearch' && value.length) {
                this.addNameSearchParams(value, httpOptions);
            } else {
                httpOptions.params = httpOptions.params.set(key, value);
            }
        }

        return this.http.get<AppInstance<T>[]>(this.APPINSTANCE_URL, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving data from Relationship One. Please try again later.'
                );
                return of<AppInstance<T>[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppInstanceService.advancedSearch'))
        );
    }

    private addDataSearchParams(dataSearch: AdvancedSearch[], httpOptions: any): void {
        dataSearch.forEach((searchCriteria, i) => {
            httpOptions.params = httpOptions.params.set('dataSearch[' + i + '].property', searchCriteria.property);
            httpOptions.params = httpOptions.params.set('dataSearch[' + i + '].value', searchCriteria.value);
            if (searchCriteria.operator) {
                httpOptions.params = httpOptions.params.set('dataSearch[' + i + '].operator', searchCriteria.operator);
            }
        });
    }

    private addNameSearchParams(nameSearch: AdvancedSearch[], httpOptions: any): void {
        nameSearch.forEach((searchCriteria, i) => {
            httpOptions.params = httpOptions.params.set('nameSearch[' + i + '].property', searchCriteria.property);
            httpOptions.params = httpOptions.params.set('nameSearch[' + i + '].value', searchCriteria.value);
            if (searchCriteria.operator) {
                httpOptions.params = httpOptions.params.set('nameSearch[' + i + '].operator', searchCriteria.operator);
            }
        });
    }

    getAppInstancesCount(
        createdAtdate: string,
        operator: string,
        ownershipOperator: any,
        ownershipValue: any
    ): Observable<CountResponse[]> {
        this.spinnerService.addProcess('AppInstanceService.getAppInstancesCount', 'Loading app instance count...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };

        let queryUrl =
            `${this.APPINSTANCE_URL}/counts?` +
            this.nameCountUtilsService.makeParams(operator, createdAtdate, ownershipOperator, ownershipValue) +
            'queryScope=all';

        return this.http.get<CountResponse[]>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving your cloud user info. Please try again later.'
                );
                return of<CountResponse[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AppInstanceService.getAppInstancesCount'))
        );
    }
}
