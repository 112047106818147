import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from 'projects/internal/r1-component-library/src/lib/services/appcloud';
import { LoginForm } from '../../../models/login-form.model';
import { AppcloudCredential } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'r1-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
    private rememberMeCookieName = 'username';
    errorMessage: string = '';
    secondFailedInvalidCreds: boolean = false;
    loginFormGroup!: FormGroup<LoginForm>;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private cookieService: CookieService
    ) {}

    ngOnInit() {
        this.loginFormGroup = this.formBuilder.group(new LoginForm());
        this.initializeFormFromRememberMeCookies();
    }

    onSubmit(): void {
        if (this.loginFormGroup.valid) {
            this.submitLoginForm({
                username: this.loginFormGroup.value.username,
                password: this.loginFormGroup.value.password
            });
        }
    }

    public showError = (control: string, error: string) => {
        if (this.loginFormGroup) {
            const formControl = this.loginFormGroup.get(control) as FormControl;
            return formControl.hasError(error);
        }
    };

    public submitLoginForm(credentials: Pick<AppcloudCredential, 'username' | 'password'>) {
        this.errorMessage = '';
        this.secondFailedInvalidCreds = false;
        this.authenticationService.loginAppCloudAdmin(credentials.username, credentials.password).subscribe(
            (oauthToken) => {
                if (oauthToken.access_token) {
                    this.errorMessage = '';
                    this.setRememberMeCookies();
                    this.router.navigate(['admin/metrics'], { queryParamsHandling: 'merge' });
                }
            },
            (err) => {
                this.setLoginErrorMessage(err);
            }
        );
    }

    private setLoginErrorMessage(err: any) {
        if (err.error) {
            // Valid user with invalid credentials, or a non-user
            switch (err.error.error) {
                case 'unauthorized': // Non-user
                    this.errorMessage = 'Invalid credentials';
                    break;
                case 'invalid_grant': // User with wrong credentials
                    this.setInvalidGrantErrorMessage(err);
                    break;
                default:
                    this.errorMessage = 'Unknown error';
                    break;
            }
        } else {
            // Valid user without admin/support scope
            this.errorMessage = 'Unauthorized access';
        }
    }

    private setInvalidGrantErrorMessage(err: any) {
        switch (err.error.error_description) {
            case 'Invalid credentials':
                this.setInvalidCredentialsErrorMessage(err);
                break;
            case 'User account is locked.':
                this.errorMessage = 'Too many login attempts. Your account is now locked. Please try again later.';
                break;
            default:
                this.errorMessage = 'Unknown error';
                break;
        }
    }

    private setInvalidCredentialsErrorMessage(err: any) {
        let failedAttempts = err.error.failedAttempts;
        if (+failedAttempts === 3) {
            this.errorMessage =
                "Hold on there, cowpoke! \
                 You've tried three times already. \
                 Two more times and we'll give you a timeout!";
        } else if (+failedAttempts === 5) {
            this.errorMessage = 'Too many login attempts. Your account is now locked. Please try again later.';
        } else {
            this.errorMessage = 'Invalid credentials';
            // strictly for bolding UI on attempt #2 to differentiate it from 'Invalid credentials' #1
            if (failedAttempts === 2) {
                this.secondFailedInvalidCreds = true;
            }
        }
    }

    private setRememberMeCookies() {
        if (this.loginFormGroup.controls['rememberMe'].value) {
            this.cookieService.set(
                this.rememberMeCookieName,
                this.loginFormGroup.controls['username'].value,
                undefined,
                '/',
                undefined,
                true,
                'None'
            );
        } else {
            this.cookieService.delete(this.rememberMeCookieName);
        }
    }

    private initializeFormFromRememberMeCookies() {
        if (this.cookieService.get(this.rememberMeCookieName)) {
            this.loginFormGroup.controls['rememberMe'].setValue(true);
            this.loginFormGroup.controls['username'].setValue(this.cookieService.get(this.rememberMeCookieName));
        }
    }
}
