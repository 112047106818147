import { AlertCriteria, AppInstance } from '../appcloud/index';
import { BasicAlertCriteriaGenerator } from './alert-criteria.generator';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppInstanceAlertCriteriaGenerator extends BasicAlertCriteriaGenerator<AppInstance<any>> {

    convertData(alertCriteria: AlertCriteria, data: AppInstance<any>, globalId: string): {} {
        let result = {};
        result['accountId'] = data.accountId;
        result['appId'] = data.app.id;
        result['eloquainstallId'] = data.eloquainstall.id;
        result['appinstanceId'] = data.id;
        return result;
    }
}