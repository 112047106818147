<div class="grid-container">
    <mat-grid-list cols="4" rowHeight="350px">
        <!-- Single points: Cloud Users / Accounts / App Instances / MRR -->
        <mat-grid-tile
            *ngFor="let card of cards; let i = index"
            [colspan]="card.cols"
            [rowspan]="card.rows"
            class="mat-grid-tile-title-bold"
        >
            <r1-metrics-single-datapoint [card]="cards[i]" [readAs]="card.title"></r1-metrics-single-datapoint>
        </mat-grid-tile>
        <!-- Example ngx-chart, waiting for actual story (had time to work on this before the actual story) -->
        <!-- <mat-grid-tile [colspan]="4" [rowspan]="1" class="mat-grid-tile-title-bold">
            <r1-metrics-chart-datapoint></r1-metrics-chart-datapoint>
        </mat-grid-tile> -->
    </mat-grid-list>
</div>
<r1-loading-spinner *ngIf="isLoading$ | async" [loadingMessages]="loadingMessages$ | async"></r1-loading-spinner>
