// Keep parity with r1-branding.scss
export class R1BrandingConstants {
    static black: string = '#101212'; // UI color (Doesn't match branding 2018-12-19)
    static white: string = '#ffffff';
    static gray: string = '#54585a'; // Branding color (as of 2018-12-19)
    static warmGray: string = '#5b514e'; // Branding color (as of 2018-12-19)
    static smoke: string = '#9da2a6'; // Branding color (as of 2018-12-19)
    static lightGray: string = '#ebebeb'; // UI color (Doesn't match branding 2018-12-19)
    static faintGray: string = '#efefef'; // Branding color (as of 2018-12-19)
    static darkBlue: string = '#01426a'; // UI color (Doesn't match branding 2018-12-19)
    static brilliantBlue: string = '#1174b6'; // UI color (Doesn't match branding 2018-12-19)
    static lightBlue: string = '#8db9ca'; // UI color (Doesn't match branding 2018-12-19)
    static orange: string = '#e87722'; // UI color (Doesn't match branding 2018-12-19)
    static orange2018: string = '#ff9933'; // Branding color (as of 2018-12-19)
    static red: string = '#ef3742'; // Branding color (as of 2018-12-19)
    static green: string = '#64a70b'; // UI color (Doesn't match branding 2018-12-19)
    static lime: string = '#93d500'; // Branding color (as of 2018-12-19)
    static purple: string = '#7D4C79'; // Legacy branding color (Long live purple.)

    static overlay: string = 'rgba(58, 39, 39, 0.5)';

    static fontFamily: string = '"Open Sans", Arial, Helvetica, sans-serif"';

    static font10: string = '10px';
    static font12: string = '12px';
    static font14: string = '14px';
    static font16: string = '16px';
    static font18: string = '18px';
    static font20: string = '20px';
    static font24: string = '24px';
}
