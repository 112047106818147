import { NgModule } from '@angular/core';
import { R1ComponentLibraryComponent } from './r1-component-library.component';
import { ComponentsModule } from './components/components.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DraggableDirective } from './directives';
import { PipesModule } from './pipes/pipes.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    imports: [ComponentsModule, PipesModule, ReactiveFormsModule, FormsModule],
    declarations: [R1ComponentLibraryComponent, DraggableDirective],
    exports: [ComponentsModule, DraggableDirective, PipesModule],
    providers: [CookieService]
})
export class R1ComponentLibraryModule {}
