import { trigger, state, style, transition, animate } from '@angular/animations';

// WARNING: All the properties here, width and animation time, are directly
//    correlated `SidenavComponent`'s `onSidenavHoverToggle()` animation widths/ms-eases.
export const onSideNavChange = trigger('onSideNavChange', [
    state(
        'close',
        style({
            'min-width': '60px'
        })
    ),
    state(
        'open',
        style({
            'min-width': '220px'
        })
    ),
    transition('close => open', animate('200ms ease-in')),
    transition('open => close', animate('200ms ease-in'))
]);

export const animateText = trigger('animateText', [
    state(
        'hide',
        style({
            display: 'none'
        })
    ),
    state(
        'show',
        style({
            // displays in respective style
        })
    ),
    transition('close => open', animate('200ms ease-in')),
    transition('open => close', animate('200ms ease-out'))
]);
