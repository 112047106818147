import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {
    public showLinkText$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    private drawer: MatDrawer;

    setDrawerElement(drawer: MatDrawer) {
        this.drawer = drawer;
    }

    toggleSideDrawerOnClick(): void {
        this.drawer.toggle();
    }
}
