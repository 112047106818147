import { Component, OnInit } from '@angular/core';
import { animateText } from '../animations/sidenav-expansion';
import { SidenavService } from '../services/sidenav.service';

/**
 * Internal component of `r1-sidenav`
 */
@Component({
    selector: 'r1-nav-expansion',
    template: `
        <div class="nav-expansion-container">
            <fa-icon [icon]="['fas', 'bars']" class="fa-lg fa-fw" (click)="toggleMenuExpansionOpen()"></fa-icon>
            <div class="nav-title" [@animateText]="showLinkText ? 'show' : 'hide'">App Configuration</div>
        </div>
    `,
    styles: ['.nav-expansion-container { display: flex;} .nav-title { font-size: 16px; margin-left: 5px;}'],
    animations: [animateText]
})
export class NavExpansionComponent implements OnInit {
    constructor(private sidenavService: SidenavService) {}

    showLinkText: boolean;

    ngOnInit() {
        this.sidenavService.showLinkText$.subscribe((res) => (this.showLinkText = res));
    }

    toggleMenuExpansionOpen() {
        this.showLinkText = !this.showLinkText;
    }
}
