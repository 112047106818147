import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { SearchObject } from '../../models/search.model';
import { AccountModel } from './models/account-state.model';
import { UpdateAccountList, UpdateSearchObject } from './actions/account-paging.actions';
import { Injectable } from '@angular/core';

const DEFAULT_STATE = {
    searchObject: new SearchObject(),
    account: new Account(),
    accountList: Array<Account>()
};

@State<AccountModel>({
    name: 'accountmanager',
    defaults: DEFAULT_STATE
})
export class AccountManagerState {
    public static defaultState = DEFAULT_STATE;

    @Selector()
    static searchObject(state: AccountModel) {
        return state.searchObject;
    }

    @Selector()
    static account(state: AccountModel) {
        return state.account;
    }

    @Selector()
    static accountList(state: AccountModel) {
        return state.accountList;
    }

    @Action(UpdateSearchObject)
    updateSearchObject({ patchState }: StateContext<AccountModel>, { searchObject }: UpdateSearchObject) {
        patchState({ searchObject: searchObject });
    }

    @Action(UpdateAccountList)
    updateAccountList({ patchState }: StateContext<AccountModel>, { accounts }: UpdateAccountList) {
        patchState({ accountList: accounts });
    }
}
