import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ChangeWarningData {
    message?: string;
    messageContainsHtml?: boolean;
    leftButton?: Button;
    rightButton?: Button;
}

export interface Button {
    text?: string;
    type?: string;
    color?: string;
}

@Component({
    selector: 'r1-change-config-warning',
    templateUrl: './change-config-warning.component.html',
    styleUrls: ['./change-config-warning.component.scss']
})
export class ChangeConfigWarningComponent implements OnInit {
    static DEFAULT_MESSAGE_TEXT: string = 'Making this change will reset your configuration. Click "Yes" to confirm, "No" to cancel.';
    static DEFAULT_MESSAGE_CONTAINS_HTML: boolean = false;

    static DEFAULT_LEFT_BUTTON_TEXT: string = 'No';
    static DEFAULT_LEFT_BUTTON_TYPE: string = 'stroked';
    static DEFAULT_LEFT_BUTTON_COLOR: string = '';
    static DEFAULT_RIGHT_BUTTON_TEXT: string = 'Yes';
    static DEFAULT_RIGHT_BUTTON_TYPE: string = 'flat';
    static DEFAULT_RIGHT_BUTTON_COLOR: string = 'black';

    static DEFAULT_LEFT_BUTTON: Button = {
        text: ChangeConfigWarningComponent.DEFAULT_LEFT_BUTTON_TEXT,
        type: ChangeConfigWarningComponent.DEFAULT_LEFT_BUTTON_TYPE,
        color: ChangeConfigWarningComponent.DEFAULT_LEFT_BUTTON_COLOR
    };

    static DEFAULT_RIGHT_BUTTON: Button = {
        text: ChangeConfigWarningComponent.DEFAULT_RIGHT_BUTTON_TEXT,
        type: ChangeConfigWarningComponent.DEFAULT_RIGHT_BUTTON_TYPE,
        color: ChangeConfigWarningComponent.DEFAULT_RIGHT_BUTTON_COLOR
    }

    message: string;
    messageContainsHtml: boolean;

    leftButton: Button;
    rightButton: Button;


    constructor(
        public dialogRef: MatDialogRef<ChangeConfigWarningComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChangeWarningData
    ) {}

    ngOnInit() {
        this.leftButton = { ...ChangeConfigWarningComponent.DEFAULT_LEFT_BUTTON, ...this.data?.leftButton };
        this.rightButton = { ...ChangeConfigWarningComponent.DEFAULT_RIGHT_BUTTON, ...this.data?.rightButton };
        this.message = this.data?.message || ChangeConfigWarningComponent.DEFAULT_MESSAGE_TEXT;
        this.messageContainsHtml = this.data?.messageContainsHtml || ChangeConfigWarningComponent.DEFAULT_MESSAGE_CONTAINS_HTML;
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onConfigChange(): void {
        this.dialogRef.close(true);
    }
}
