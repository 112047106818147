import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, FormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

/**
 * Allows viewing a form's values in template, as well as its overall validity. Also has button that logs that data in depth to console.
 *
 * REQUIRED `formElement: FormGroup | FormArray` - Form array or group to display. May need to use a shared `r1_castAs_X` pipe in template
 *
 * OPTIONAL `formName: string` - Name to give the form
 */
@Component({
    standalone: true,
    selector: 'r1-util-form-preview',
    imports: [CommonModule, MatExpansionModule, FormsModule, ReactiveFormsModule],
    template: `
        <mat-accordion>
            <mat-expansion-panel [expanded]="expanded" id="util-form-preview-container">
                <mat-expansion-panel-header>
                    <mat-panel-title>"{{ formName }}" {{ formElementType }}</mat-panel-title>
                </mat-expansion-panel-header>

                <div id="r1-util-form-preview">
                    <div [ngSwitch]="formElementType">
                        <div *ngSwitchCase="'FormGroup'">
                            <pre *ngIf="formElementType === 'FormGroup'">{{ formElement?.value | json }}</pre>
                        </div>
                        <div *ngSwitchCase="'FormArray'">
                            <ng-container *ngFor="let formItem of formElement?.value">
                                <pre [attr.data-testid]="formItem">{{ formItem | json }}</pre>
                            </ng-container>
                            <pre>length: {{ $any(formElement)?.length }}</pre>
                        </div>
                    </div>

                    <pre><span [style.background-color]="formElement?.valid ? '#66FF99': '#FFCCCB'">Valid: {{ formElement?.valid | json }}</span></pre>
                    <button (click)="logForm()">log form</button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    `,
    styles: [
        `
            #util-form-preview-container {
                border: 2px dotted red;
                margin: 8px;
                display: table;
            }

            #r1-util-form-preview {
                font-size: 12px;
            }
        `
    ]
})
export class UtilFormPreviewComponent implements OnInit {
    @Input()
    formElement!: UntypedFormGroup | UntypedFormArray;

    @Input()
    formName?: string = 'Untitled';

    @Input()
    expanded?: boolean = false;

    formElementType: 'FormGroup' | 'FormArray';

    constructor() {}

    ngOnInit(): void {
        this.formElement instanceof UntypedFormArray
            ? (this.formElementType = 'FormArray')
            : (this.formElementType = 'FormGroup');

        this.formElement.valueChanges.subscribe((res) => {
            // mess with if you want
        });

        console.warn(`Debug element present, showing ${this.formName}`, this.formElement);
    }

    logForm(): void {
        console.group(this.formName);
        console.group('Form element');
        console.log(this.formElement);
        console.log(this.formElement.controls);
        console.log(this.formElement.value);
        console.groupEnd();

        if (this.formElementType === 'FormArray') {
            console.group('Form iterated');
            const formArray = this.formElement as UntypedFormArray;
            formArray.controls.forEach((ele, index) => {
                const element = ele as UntypedFormGroup;
                console.log(`index: ${index}`);
                console.log(element.controls);
                console.log(element.value);
            });
            console.groupEnd();
        } else if (this.formElementType === 'FormGroup') {
            // this can be fleshed out later
            // console.group('Form iterated');
            // const formGroup = this.formElement as FormGroup;
            // Object.keys(formGroup.controls).forEach((key) => {
            //     const element = formGroup.controls[key] as FormControl;
            // });
        } else {
            console.error('Invalid form element type');
        }

        console.groupEnd();
        console.log('');
    }
}
