import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { CloudUser } from 'projects/internal/r1-component-library/src/lib/models/appcloud/cloud-user.model';
import { CloudUserModel } from './models/cloud-user-state.model';
import { UpdateCloudUserData } from './actions/cloud-user.actions';

const DEFAULT_STATE = {
    cloudUser: new CloudUser()
};

@State<CloudUserModel>({
    name: 'clouduser',
    defaults: DEFAULT_STATE
})
@Injectable()
export class CloudUserState {
    public static defaultState = DEFAULT_STATE;

    @Selector()
    static cloudUser(state: CloudUserModel) {
        return state.cloudUser;
    }

    constructor() {}

    @Action(UpdateCloudUserData)
    updateCloudUser({ patchState }: StateContext<CloudUserModel>, { cloudUser }: UpdateCloudUserData) {
        patchState({ cloudUser: cloudUser });
    }
}
