<ng-container *ngIf="showLinkText; else hideLinkText">
    <p matSubheader *ngIf="subheaderTitle" [@animateText]="showLinkText ? 'show' : 'hide'">
        {{ subheaderTitle }}
    </p>
</ng-container>

<ng-template #hideLinkText>
    <hr *ngIf="subheaderTitle" class="hr-divider" />
</ng-template>

<ng-container *ngFor="let link of links">
    <!--
        Angular router is intended for internal navigation, not external;
        hence this first case is for internal links to use the router API,
        and the other to open outside links
    -->
    <div *ngIf="link.path; else externalLink">
        <mat-list-item
            class="list-items-wrapper"
            [routerLink]="link.path"
            routerLinkActive="active-list-item"
            [routerLinkActiveOptions]="routerMatchOptions"
            queryParamsHandling="merge"
        >
            <fa-icon [icon]="link.faIcon" class="fa-lg fa-fw"></fa-icon>
            <p [@animateText]="showLinkText ? 'show' : 'hide'" class="link-text">
                {{ link.name }}
            </p>
        </mat-list-item>
    </div>

    <ng-template #externalLink>
        <mat-list-item class="list-items-wrapper" (click)="openExternalLink(link.externalPath)">
            <fa-icon [icon]="link.faIcon" class="fa-lg fa-fw"></fa-icon>
            <p [@animateText]="showLinkText ? 'show' : 'hide'" class="link-text">
                {{ link.name }}
            </p>
        </mat-list-item>
    </ng-template>
</ng-container>
