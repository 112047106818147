import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QueryBuilderService {
    constructor() {}

    fieldOperatorValue(field: string, operator: string, value: string) {
        return `${encodeURIComponent(field)}.operator=${encodeURIComponent(operator)}&${encodeURIComponent(
            field
        )}.value=${encodeURIComponent(value)}`;
    }

    searchAccountsQueryBuilder(filterValue = '', caseSensitive?: boolean): string {
        let queryUrl = '';

        queryUrl += `${encodeURIComponent('crossFieldSearch[0].searchValue')}=${filterValue}` + '&';

        queryUrl += `${encodeURIComponent('crossFieldSearch[0].matchingCriteria[0].field')}=name` + '&';
        queryUrl += `${encodeURIComponent('crossFieldSearch[0].matchingCriteria[0].operator')}=contains` + '&';

        queryUrl += `${encodeURIComponent('crossFieldSearch[0].matchingCriteria[1].field')}=id` + '&';
        queryUrl += `${encodeURIComponent('crossFieldSearch[0].matchingCriteria[1].operator')}=equals` + '&';

        if (caseSensitive) {
            queryUrl +=
                `${encodeURIComponent(
                    'crossFieldSearch[0].matchingCriteria[0].caseSensitive'
                )}=${caseSensitive.toString()}` + '&';
            queryUrl += `${encodeURIComponent(
                'crossFieldSearch[0].matchingCriteria[1].caseSensitive'
            )}=${caseSensitive.toString()}`;
        }

        return queryUrl;
    }
}
