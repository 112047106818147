import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirefoxCookiesWarningComponent } from './firefox-cookies-warning.component';
import { InstructionsModule } from '../../instructions/instructions.module';

@NgModule({
    declarations: [FirefoxCookiesWarningComponent],
    imports: [CommonModule, InstructionsModule],
    exports: [FirefoxCookiesWarningComponent]
})
export class FirefoxCookiesWarningModule {}
