import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicStringSortPipe } from './basic-string-sort.pipe';
import { ContactFieldSortPipe } from './eloqua/contact-field-sort.pipe';
import { CdoSortPipe } from './eloqua/cdo-sort.pipe';
import { CdoFieldSortPipe } from './eloqua/cdo-field-sort.pipe';
import { AccountFieldSortPipe } from './eloqua/account-field-sort.pipe';
import { CampaignFieldSortPipe } from './eloqua/campaign-field-sort.pipe';
import { FieldMergeSortPipe } from './appcloud/merge-field-sort.pipe';
import { EmptyValuesPipe } from './empty-values.pipe';
import { NetsuiteAddressFieldSortFilterPipe} from './appcloud/netsuite-address-field-sort-filter.pipe';
import { NetsuiteFieldSortPipe } from './appcloud/netsuite-field-sort.pipe';
import { NetsuiteImportSortPipe } from './appcloud/netsuite-import-sort.pipe';
import { ObjectSortPipe } from './salesforce/object-sort.pipe';
import { FieldSortPipe } from './salesforce/field-sort.pipe';
import { TableSortPipe } from './tfs/table-sort.pipe';
import { TfsFieldSortPipe } from './tfs/tfs-field-sort.pipe';
import { NetsuiteActionSortPipe } from './appcloud/netsuite-action-sort.pipe';
import { NetsuiteConnectionSortPipe } from './appcloud/netsuite-connection-sort.pipe';
import { CastAsFormGroupPipe } from './cast-as-formgroup.pipe';
import { CastAsFormcontrolPipe } from './cast-as-formcontrol.pipe';

@NgModule({
    declarations: [
        BasicStringSortPipe,
        ContactFieldSortPipe,
        CdoSortPipe,
        CdoFieldSortPipe,
        AccountFieldSortPipe,
        CampaignFieldSortPipe,
        FieldMergeSortPipe,
        EmptyValuesPipe,
        NetsuiteFieldSortPipe,
        NetsuiteAddressFieldSortFilterPipe,
        NetsuiteActionSortPipe,
        NetsuiteConnectionSortPipe,
        NetsuiteImportSortPipe,
        ObjectSortPipe,
        FieldSortPipe,
        TableSortPipe,
        TfsFieldSortPipe,
        CastAsFormGroupPipe,
        CastAsFormcontrolPipe
    ],
    imports: [CommonModule],
    exports: [
        BasicStringSortPipe,
        ContactFieldSortPipe,
        CdoSortPipe,
        CdoFieldSortPipe,
        AccountFieldSortPipe,
        FieldMergeSortPipe,
        CampaignFieldSortPipe,
        EmptyValuesPipe,
        NetsuiteFieldSortPipe,
        NetsuiteAddressFieldSortFilterPipe,
        NetsuiteActionSortPipe,
        NetsuiteConnectionSortPipe,
        NetsuiteImportSortPipe,
        ObjectSortPipe,
        FieldSortPipe,
        TableSortPipe,
        TfsFieldSortPipe,
        CastAsFormGroupPipe,
        CastAsFormcontrolPipe
    ]
})
export class PipesModule {}
