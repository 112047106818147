import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { animateText, onSideNavChange } from './animations/sidenav-expansion';
import { SidenavService } from './services/sidenav.service';
import { NavLinkGrouping } from './models/nav-link.model';

/**
 * Enables grouping of `NavLink`.
 *
 * NOTE: Must include `SidenavService` in the `AppModule` providers array of the project using this component
 *
 * -REQUIRED `sidenavLinksGroupingArray: Array<NavLinkGrouping>;` Pass in the `NavLinkGrouping` arrays in order of top down groupings.
 *
 * -REQUIRED Add respective FA icons to this library component's `fa-icons.modue.ts`
 */
@Component({
    selector: 'r1-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: [onSideNavChange, animateText]
})
export class SidenavComponent implements OnInit, AfterViewInit {
    @ViewChild('drawer') public drawer: MatDrawer;
    showLinkText: boolean;
    @Input() sidenavLinksGroupingArray: Array<NavLinkGrouping>;
    @Input() initialOpenState?: boolean;

    constructor(private sidenavService: SidenavService) {}

    ngOnInit() {
        this.sidenavService.showLinkText$.subscribe((res) => (this.showLinkText = res));
    }

    ngAfterViewInit() {
        this.sidenavService.setDrawerElement(this.drawer);
    }

    onSidenavExpansionToggle() {
        this.sidenavService.showLinkText$.next(!this.showLinkText);

        // WARNING: All the properties here, width and animation time, are directly
        //    correlated `sidenav-expansion.ts's animation widths/ms-eases.
        //
        // TL;DR: Material has a property to change the projected content's size with the sidenav expanding, but it is poorly supported.
        // If you look up "angular material sidenav margin-left" bugs you will find that the `mat-drawer-content` doesn't
        //    responsively re-adjust it's left-margin on collapse of the sidenav, leaving a big empty gap.
        //    Long story short, you can still use the angular animations to grow/shrink the sidenav on a particular timing
        //    because the HTML markup can target `mat-drawer` directy, but not the encapsulated `mat-drawer-content`.
        //            IE)      body > r1-root > r1-sidenav > mat-drawer-container > mat-drawer-content
        //    This is merely a direct change to the DOM to fix the margin when the animated drawer collapes.
        let content = Array.from(
            document.getElementsByClassName('mat-drawer-content') as HTMLCollectionOf<HTMLElement>
        );
        if (this.showLinkText) {
            content[0].style.transition = '200ms ease-in';
            content[0].style.marginLeft = '220px';
        } else {
            content[0].style.transition = '200ms ease-out';
            content[0].style.marginLeft = '60px';
        }
    }
}
