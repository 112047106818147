import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from 'projects/internal/r1-component-library/src/lib/services/appcloud';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard  {
    constructor(private authService: AuthenticationService, private router: Router) {}

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): true | UrlTree {
        let isLoggedIn = this.authService.isAuthenticated();
        if (isLoggedIn) {
            return true;
        }

        this.authService.redirectUrl = url;
        return this.router.parseUrl('/login');
    }
}
