import { TimeUnits } from './time.constants';

export class TimeDelay {
    displayName?: string;
    interval: number;
    unit: TimeUnits;
}

export class Severity {
    type: string;
    code: number;
}

export class AlertConstants {
    static TimeDelayFrequencies: TimeDelay[] = [
        {
            displayName: '30 Minutes',
            interval: 30,
            unit: TimeUnits.MINUTES
        },
        {
            displayName: '60 Minutes',
            interval: 60,
            unit: TimeUnits.MINUTES
        },
        {
            displayName: '2 Hours',
            interval: 2,
            unit: TimeUnits.HOURS
        },
        {
            displayName: '4 Hours',
            interval: 4,
            unit: TimeUnits.HOURS
        },
        {
            displayName: '8 Hours',
            interval: 8,
            unit: TimeUnits.HOURS
        },
        {
            displayName: '12 Hours',
            interval: 12,
            unit: TimeUnits.HOURS
        },
        {
            displayName: '24 Hours',
            interval: 24,
            unit: TimeUnits.HOURS
        }
    ];

    static Severity = class {
        static CATASTROPHIC: Severity = {
            type: 'Catastrophic Failure',
            code: 100
        };
        static CORRUPTION: Severity = {
            type: 'Data Loss or Corruption',
            code: 200
        };
        static OUTAGE: Severity = {
            type: 'Outage',
            code: 300
        };
        static MAJOR: Severity = {
            type: 'Major',
            code: 400
        };
        static STANDARD: Severity = {
            type: 'Standard',
            code: 500
        };
        static WARNING: Severity = {
            type: 'Warning',
            code: 600
        };
        static PERFORMANCE: Severity = {
            type: 'Performance Issue',
            code: 700
        };
        static NOTABLE: Severity = {
            type: 'Notable',
            code: 800
        };
        static INFORMATIONAL: Severity = {
            type: 'Informational',
            code: 900
        };
    };

    static AlertCategory = class {
        static AppStats = 'APP_STATS';
        static AuxDb = 'AUX_DB';
        static Oauth = 'OAUTH';
    };

    static Analysis = class {
        static RecordsErrored = 'RECORDS_MARKED_ERRORED';
        static ProcessingIssues = 'PROCESSING_ISSUES';
        static AppStatsResultStatusUpdated = 'APP_STATS_RESULT_STATUS_UPDATED';
        static AppStatsResultStatusStuckInProgress = 'APP_STATS_RESULT_STATUS_STUCK_IN_PROGRESS';
        static SeiScoringRecordsStuckInProgress = 'SEI_SCORING_RECORDS_STUCK_IN_PROGRESS';
        static ExpiredEloquaOauth = 'EXPIRED_ELOQUA_OAUTH';
    };

    static AnalysisCriteria = class {
        static readonly DEFAULT = {};
        static readonly DEFAULT_APPSTATS_ERROR_STATUS = { status: ['Failure', 'PartialSuccess'] };
        static readonly DEFAULT_TFS_TP_ERROR_STATUS = { status: ['Failure', 'PartialSuccess'] };
        static readonly DEFAULT_OAUTH_INVALID = { property: 'oauthValid', operator: '=', values: ['false'] };
        static readonly DEFAULT_LAST_NOTIFIED = { property: 'lastNotified', operator: '>', values: [] };
        static readonly DEFAULT_EXPIRED_ELOQUA_OAUTH = { criteria: [ this.DEFAULT_OAUTH_INVALID, this.DEFAULT_LAST_NOTIFIED] };
    };

    static EmailTemplate = class {
        static SeiScoringRecordsInProgress = 'SeiScoringRecordsInProgress';
        static TFSTalkingPointsRecordsInProgress = 'TalkingPointsCreatorUpdaterRecordsInProgress';
        static AppStatsResultStatusUpdate = 'AppStatsResultStatusUpdate';
        static TFSTalkingPointsResultStatusUpdate = 'TalkingPointsResultStatusUpdate';
        static ExpiredEloquaOauth = 'ExpiredEloquaOauth';
    };
}
