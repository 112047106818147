import { Component, Input } from '@angular/core';
import { AppcloudCountsService } from 'projects/appcloud-admin-portal/src/app/services/appcloud-counts.service';
import { MetricsCard, MetricsTimespanOption } from 'projects/appcloud-admin-portal/src/app/models/metrics-card.model';

@Component({
    selector: 'r1-metrics-single-datapoint',
    templateUrl: './metrics-single-datapoint.component.html',
    styleUrls: ['./metrics-single-datapoint.component.scss']
})
export class MetricsSingleDatapointComponent {
    constructor(private appcloudCountsService: AppcloudCountsService) {}

    @Input() readAs!: string;
    @Input() card!: MetricsCard;

    options: MetricsTimespanOption[] = [
        { readAs: 'Total', value: this.appcloudCountsService.today, operator: '<=' },
        { readAs: '30 Days', value: this.appcloudCountsService.daysAgo30, operator: '>=' },
        { readAs: '60 Days', value: this.appcloudCountsService.daysAgo60, operator: '>=' },
        { readAs: '90 Days', value: this.appcloudCountsService.daysAgo90, operator: '>=' }
    ];

    mrrOptions: MetricsTimespanOption[] = [
        {
            readAs: 'Previous Month',
            periodStart: this.appcloudCountsService.previousMonth,
            periodEnd: this.appcloudCountsService.endOfPreviousMonth
        },
        {
            readAs: 'Current Month',
            periodStart: this.appcloudCountsService.startOfCurrentMonth,
            periodEnd: this.appcloudCountsService.endOfCurrentMonth
        },
        { readAs: 'Year to Date' }
    ];

    toggleOption(option: MetricsTimespanOption) {
        this.card.about = option.readAs;
        let readAs = this.card.readAs;

        // TODO: Change backend to ownedByR1 flag?
        if (readAs === 'Cloud Users') {
            this.appcloudCountsService.getUserCounts(
                option.value,
                option.operator,
                'regexMatch',
                '^(?!.*relationshipone.com)'
            );
        } else if (readAs === 'Accounts') {
            this.appcloudCountsService.getAccountCounts(option.value, option.operator, 'ownedByR1', 'false');
        } else if (readAs === 'App Instances') {
            this.appcloudCountsService.getAppInstanceCounts(option.value, option.operator, 'ownedByR1', 'false');
        } else if (readAs === 'MRR') {
            if (option.readAs === 'Year to Date') {
                this.appcloudCountsService.getYTD_MRR();
            } else {
                this.appcloudCountsService.getTotalMRR(option.periodStart, option.periodEnd);
            }
        } else {
            console.error('Unable to retrieve data for this anyalytic named', this.card.readAs);
        }
    }
}
