import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'r1-http-error-snack-bar',
    templateUrl: './http-error-snack-bar.component.html',
    styleUrls: ['./http-error-snack-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HttpErrorSnackBarComponent {
    constructor(private matSnackBarService: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    onDismiss(): void {
        this.matSnackBarService.dismiss();
    }
}
