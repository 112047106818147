<!-- Keep in mind that this is directly in a mat-sidenav, and that sidenav isn't included
    in this template because mat-sidenav elements DO NOT like being broken into levels -->
<div>
    <h1>{{ account.name }}</h1>
    <p *ngIf="account.description"><mat-icon>description</mat-icon>{{ account.description }}</p>
    <p *ngIf="account.sfAccountId"><mat-icon>wb_cloudy</mat-icon>SFDC: {{ account.sfAccountId }}</p>

    <p><mat-icon>fingerprint</mat-icon>Account ID : {{ account.id }}</p>
    <p><mat-icon>check_circle_outline</mat-icon>Created: {{ account.createdAt }}</p>
    <p><mat-icon>history</mat-icon>Last Updated: {{ account.updatedAt }}</p>
</div>
