export class PostgresConstants {
    static dataTypeDisplayNames: Map<string, string> = new Map<string, string>()
        .set('bigint', 'Number (Integer)')
        .set('bigserial', 'Number (Integer)')
        .set('int', 'Number (Integer)')
        .set('int4', 'Number (Integer)')
        .set('int8', 'Number (Integer)')
        .set('integer', 'Number (Integer)')
        .set('serial', 'Number (Integer)')
        .set('serial4', 'Number (Integer)')
        .set('serial8', 'Number (Integer)')
        .set('bigserial', 'Number (Decimal)')
        .set('decimal', 'Number (Decimal)')
        .set('double precision', 'Number (Decimal)')
        .set('double', 'Number (Decimal)')
        .set('float', 'Number (Decimal)')
        .set('float8', 'Number (Decimal)')
        .set('numeric', 'Number (Decimal)')
        .set('real', 'Number (Decimal)')
        .set('bool', 'Boolean')
        .set('boolean', 'Boolean')
        .set('char', 'Text')
        .set('varchar', 'Text')
        .set('text', 'Text')
        .set('date', 'Date')
        .set('timestamp', 'Date')
        .set('timestamptz', 'Date');
}
