import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CredentialsContainerComponent } from './shared-subcomponents/credentials-container/credentials-container.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SalesforceAuthorizationComponent } from './salesforce-authorization/salesforce-authorization.component';
import { InstructionsModule } from '../instructions/instructions.module';
import { EloquaAuthorizationComponent } from './eloqua-authorization/eloqua-authorization.component';
import { GoogleAuthorizationComponent } from './google-authorization/google-authorization.component';
import { UnityAuthorizationComponent } from './unity-authorization/unity-authorization.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { UtilFormPreviewComponent } from '../utility';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
    declarations: [CredentialsContainerComponent, SalesforceAuthorizationComponent, EloquaAuthorizationComponent, GoogleAuthorizationComponent, UnityAuthorizationComponent],
    imports: [CommonModule, MatButtonModule, FlexLayoutModule, InstructionsModule, MatSlideToggleModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
    exports: [CredentialsContainerComponent, SalesforceAuthorizationComponent, EloquaAuthorizationComponent, GoogleAuthorizationComponent, UnityAuthorizationComponent]
})
export class CredentialsModule {}
