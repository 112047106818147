import { AfterViewInit, Component, ViewChild, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { fromEvent, merge } from 'rxjs';

import { ManageAccountsService } from 'projects/appcloud-admin-portal/src/app/services/manage-accounts.service';
import { AccountsDataSource } from '../../../../../services/accounts.datasource';
import { SearchObject } from 'projects/appcloud-admin-portal/src/app/models/search.model';
import { UpdateSearchObject } from '../../../../states/actions/account-paging.actions';
import { AccountManagerState } from '../../../../states/account-paging.state';
import { Account } from 'projects/internal/r1-component-library/src/public_api';

/**
 * Model's article on how this DataSource works https://blog.angular-university.io/angular-material-data-table/#whynotusemattabledatasource
 *
 * Model followed code: https://github.com/angular-university/angular-material-course/blob/2-data-table-finished/src/app/services/lessons.datasource.ts
 */
@Component({
    selector: 'r1-accounts-table',
    templateUrl: './accounts-table.component.html',
    styleUrls: ['./accounts-table.component.scss']
})
export class AccountsTableComponent implements AfterViewInit, OnInit {
    @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort!: MatSort;
    @ViewChild('input', { static: true }) input!: ElementRef;

    dataSource!: AccountsDataSource;
    totalElements: number = 0;
    displayedColumns = ['name', 'id', 'createdAt', 'updatedAt'];

    defaultSearchObj: SearchObject = {
        filter: '',
        page: 0,
        pageSize: 25,
        sortField: 'name',
        sortOrder: 'asc'
    };

    @Output() viewAccount = new EventEmitter<Account>();
    selectedSortField: string = '';

    constructor(private manageAccountsService: ManageAccountsService, private store: Store) {}

    editAccount(row: Account) {
        this.viewAccount.emit(row);
    }

    sortChange(event: Sort) {
        this.selectedSortField = event.active;
    }

    ngOnInit() {
        this.dataSource = new AccountsDataSource(this.manageAccountsService);
        this.loadAccountsPage(this.defaultSearchObj);

        this.dataSource.totalElementsSubject$.subscribe((numElements) => (this.totalElements = numElements));

        this.manageAccountsService.accountEditSaved$.subscribe(() => {
            const searchObject = this.store.selectSnapshot(AccountManagerState.searchObject);
            this.dataSource.loadAccounts(searchObject);
        });
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.loadAccountsPage();
                })
            )
            .subscribe();

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(() => this.loadAccountsPage()))
            .subscribe();
    }

    loadAccountsPage(defaultSearchObj?: SearchObject) {
        if (defaultSearchObj) {
            this.dataSource.loadAccounts(defaultSearchObj);
            this.store.dispatch(new UpdateSearchObject(defaultSearchObj));
        } else {
            const searchObj: SearchObject = {
                operator: '>',
                filter: (<string>this.input.nativeElement.value).trim(),
                page: this.paginator.pageIndex,
                pageSize: this.paginator.pageSize,
                sortField: this.selectedSortField,
                sortOrder: this.sort.direction
            };

            this.dataSource.loadAccounts(searchObj);
            this.store.dispatch(new UpdateSearchObject(searchObj));
        }
    }
}
