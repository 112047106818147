import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { AuthenticationPayload } from 'projects/internal/r1-component-library/src/lib/models/oauth-token.model';
import { CloudUser } from 'projects/internal/r1-component-library/src/lib/models/appcloud/cloud-user.model';
import { CloudUserState } from '../../../states/cloud-user.state';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
    selector: 'r1-welcome',
    template: `
        <div id="welcome-back-panel">
            <div id="user-welcome">
                <img src="{{ user_gravatar }}" id="gravatar" />
                <div id="name-wLogin">
                    <p id="welcome-back-name">Welcome back, {{ cloudUser.firstname | titlecase }}!</p>
                    <!-- ex) "Sun Jun 06 22:11:10 UTC 2021" -->
                    <p>
                        Last login: <b>{{ lastLogin | date: 'E LLL d HH:mm:ss z y' }}</b>
                    </p>
                </div>
            </div>
            <div id="account-select">
                <mat-toolbar>
                    <span>ACCOUNT_NAME (NUMBER)</span>
                    <span class="spacer"></span>
                    <mat-icon id="more">more_horiz</mat-icon>
                </mat-toolbar>
            </div>
        </div>
    `,
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
    constructor() {}

    @Input() userPayload: AuthenticationPayload;
    private subscriptions: Subscription[] = [];

    user_gravatar: string = '';
    lastLogin: number = null;
    username: string = null;
    public cloudUser: CloudUser = null;

    @Select(CloudUserState.cloudUser) cloudUser$: Observable<CloudUser>;

    private getGravatarURL(email: string) {
        const address = String(email).trim().toLowerCase();
        const hash = Md5.hashStr(address);
        return `https://www.gravatar.com/avatar/${hash}`;
    }

    ngOnInit() {
        this.username = this.userPayload.username;
        this.lastLogin = this.userPayload.lastLogin;
        this.user_gravatar = this.getGravatarURL(this.username);
        this.subscriptions.push(this.cloudUser$.subscribe((cloudUsr) => (this.cloudUser = cloudUsr)));
    }
}
