import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component'; // being used for JSDoc reference

/**
 * Used in conjunction with `<r1-loading-spinner />`
 * @see {@link LoadingSpinnerComponent}
 */
@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private _isLoading = new BehaviorSubject<boolean>(false);
    private _runningProcesses: BehaviorSubject<Map<string, string>> = new BehaviorSubject(new Map());

    constructor() {}

    addProcess(key: string, message?: string): void {
        let runningProcesses = this._runningProcesses.getValue();
        runningProcesses.set(key, message);
        this._runningProcesses.next(runningProcesses);
        this._isLoading.next(true);
    }

    completeProcess(key: string): void {
        let value = this._runningProcesses.getValue();
        value.delete(key);
        this._runningProcesses.next(value);
        this._isLoading.next(value.size > 0);
    }

    clearProcesses(): void {
        this._runningProcesses.next(new Map());
        this._isLoading.next(false);
    }

    isLoading(): Observable<boolean> {
        return this._isLoading.asObservable();
    }

    getMessages(): Observable<string[]> {
        return this._runningProcesses.pipe(map((runningProcesses) => Array.from(runningProcesses.values())));
    }
}
