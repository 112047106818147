import { Component, Input, OnInit } from '@angular/core';

import { animateText } from '../../animations/sidenav-expansion';
import { IsActiveMatchOptions, NavLinkGrouping } from '../../models/nav-link.model';
import { SidenavService } from '../../services/sidenav.service';

/**
 * Internal component of `r1-sidenav`
 */
@Component({
    selector: 'r1-sidenav-list',
    templateUrl: './sidenav-list.component.html',
    styleUrls: ['./sidenav-list.component.scss'],
    animations: [animateText]
})
export class SidenavListComponent implements OnInit {
    @Input() navLinkGrouping: NavLinkGrouping;

    get subheaderTitle() {
        return this.navLinkGrouping.subheaderTitle;
    }

    get links() {
        return this.navLinkGrouping.links;
    }

    readonly routerMatchOptions: IsActiveMatchOptions = {
        queryParams: 'ignored',
        matrixParams: 'exact',
        paths: 'exact',
        fragment: 'exact'
    };

    showLinkText: boolean;

    constructor(private sidenavService: SidenavService) {}

    ngOnInit(): void {
        this.sidenavService.showLinkText$.subscribe((res) => (this.showLinkText = res));
    }

    openExternalLink(externalPath: string) {
        window.open(externalPath, '_blank');
    }
}
