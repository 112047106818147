import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FieldGroupingComponent } from './field-grouping/field-grouping.component';
import { NewFieldMappingComponent } from './field-mapping/new-field-mapping.component';
import { FieldNonMatformfieldComponent } from './field-non-matformfield/field-non-matformfield.component';
import { SingleFieldComponent } from './single-field/single-field.component';
import { NGX_VALIDATION_MESSAGES_KEY, ValidationDisplayDirective, VALIDATION_MESSAGES } from './validation.directive';

@NgModule({
    declarations: [
        SingleFieldComponent,
        FieldNonMatformfieldComponent,
        NewFieldMappingComponent,
        FieldGroupingComponent,
        ValidationDisplayDirective
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, FontAwesomeModule],
    exports: [
        SingleFieldComponent,
        FieldNonMatformfieldComponent,
        NewFieldMappingComponent,
        FieldGroupingComponent,
        ValidationDisplayDirective
    ],
    providers: [
        {
            provide: NGX_VALIDATION_MESSAGES_KEY,
            useValue: {
                ...VALIDATION_MESSAGES
            }
        }
    ]
})
export class FieldModule {}
