import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of, Observable } from 'rxjs';
import { CloudUser } from '../../models/appcloud/cloud-user.model';
import { catchError, finalize } from 'rxjs/operators';
import { ErrorHandlingService } from '../error-handling/error-handling.service';
import { CountResponse } from '../../models/appcloud/count-response';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { NameCountUtilsService } from './name-count-util.service';

@Injectable({
    providedIn: 'root'
})
export class CloudUserService {
    private url: string = `${environment.publicApiHost}/appcloud`;

    constructor(
        private http: HttpClient,
        private errorHandlingService: ErrorHandlingService,
        private spinnerService: SpinnerService,
        private nameCountUtilsService: NameCountUtilsService
    ) {}

    getCloudUserById(cloudUserId: number): Observable<CloudUser> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };
        let body: URLSearchParams = new URLSearchParams();
        body.set('cloudUserId', cloudUserId.toString());
        const queryUrl = `${this.url}/cloudusers/${cloudUserId}?queryScope=all`;

        return this.http.get<CloudUser>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving your cloud user info. Please try again later.'
                );
                return of<CloudUser>();
            })
        );
    }

    getCloudUserCount(
        createdAtdate: string,
        operator: string,
        ownershipOperator: string,
        ownershipValue: string
    ): Observable<CountResponse[]> {
        this.spinnerService.addProcess('CloudUserSevice.getCloudUserCount', 'Loading clouduser count...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };

        let queryUrl =
            `${this.url}/cloudusers/counts?` +
            this.nameCountUtilsService.makeParams(operator, createdAtdate, ownershipOperator, ownershipValue) +
            'queryScope=all';

        return this.http.get<CountResponse[]>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving your cloud user info. Please try again later.'
                );
                return of<CountResponse[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('CloudUserSevice.getCloudUserCount'))
        );
    }
}
