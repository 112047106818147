import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { SearchObject } from '../../../models/search.model';

export class UpdateSearchObject {
    static readonly type = '[AccountManager] UpdateSearchObject';
    constructor(public searchObject: SearchObject) {}
}

export class UpdateAccountList {
    static readonly type = '[AccountManager] UpdateAccountList';
    constructor(public accounts: Account[]) {}
}
