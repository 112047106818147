import { TimeDelay } from '../constants/alert.constants';
import { TimeUnits } from '../constants/time.constants';
import { UntypedFormGroup } from '@angular/forms';
import { Alert, AnalysisCriteria } from '../models/alerts/alerts.model';

export class AlertUtils {

    static findTimeDelay(timeDelayFrequencies: TimeDelay[], interval: number, unit: TimeUnits): TimeDelay {
        return timeDelayFrequencies.find((frequency) => AlertUtils.compareTimeDelays(frequency, {"interval": interval, "unit": unit}))
    }

    static compareTimeDelays(a: TimeDelay, b: TimeDelay): boolean {
        if (a && b && a.unit && b.unit && a.interval && b.interval) {
            return a.unit === b.unit && a.interval === b.interval;
        }
        return false;
    }

    static anyEnabledAlerts(alerts: Alert[], alertsForm: UntypedFormGroup): boolean {
        return alerts.filter((alert) => alertsForm.get(alert.property).value).length > 0;
    }

    static combineAnalysisCriteria(analysisCriteria: AnalysisCriteria[]): AnalysisCriteria {
        let result: AnalysisCriteria = {};
        analysisCriteria.forEach((criterion) => {
            result = { ...result, ...criterion };
        });
        return result;
    }
}