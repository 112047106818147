import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconsModule } from './components/fa-icons.module';
import { NavExpansionComponent } from './components/nav-expansion-icon.component';
import { SidenavListComponent } from './components/sidenav-list/sidenav-list.component';
import { SidenavComponent } from './sidenav.component';

@NgModule({
    declarations: [SidenavComponent, SidenavListComponent, NavExpansionComponent],
    imports: [CommonModule, FontAwesomeModule, MatSidenavModule, MatListModule, FaIconsModule, RouterModule],
    exports: [SidenavComponent]
})
export class SidenavModule {}
