import { Injectable } from "@angular/core";
@Injectable()
export class MetricsCard {
    title: string;
    readAs: string;
    about: string;
    cols: number;
    rows: number;
    count?: number;
}

export class MetricsTimespanOption {
    readAs: string;
    value?: string;
    operator?: string;
    periodStart?: string;
    periodEnd?: string;
}
