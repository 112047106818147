import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
           transition('ComponentA => ComponentB', [
               style({ position: 'relative' }),
               query(':enter, :leave', [
                   style({
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: '100%'
                   })
               ]),
               query(':enter', [style({ left: '100%' })]),
               query(':leave', animateChild()),
               group([
                   query(':leave', [animate('400ms ease-out', style({ left: '-100%' }))]),
                   query(':enter', [animate('400ms ease-out', style({ left: '0%' }))])
               ]),
               query(':enter', animateChild())
           ]),
           transition('ComponentB => ComponentA', [
               style({ position: 'relative' }),
               query(':enter, :leave', [
                   style({
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: '100%'
                   })
               ]),
               query(':enter', [style({ left: '-100%' })]),
               query(':leave', animateChild()),
               group([
                   query(':leave', [animate('400ms ease-out', style({ left: '100%' }))]),
                   query(':enter', [animate('400ms ease-out', style({ left: '0%' }))])
               ]),
               query(':enter', animateChild())
           ]),
           transition('ComponentB => ComponentC', [
               style({ position: 'relative' }),
               query(':enter, :leave', [
                   style({
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: '100%'
                   })
               ]),
               query(':enter', [style({ left: '100%' })]),
               query(':leave', animateChild()),
               group([
                   query(':leave', [animate('400ms ease-out', style({ left: '-100%' }))]),
                   query(':enter', [animate('400ms ease-out', style({ left: '0%' }))])
               ]),
               query(':enter', animateChild())
           ]),
           transition('ComponentC => ComponentB', [
               style({ position: 'relative' }),
               query(':enter, :leave', [
                   style({
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: '100%'
                   })
               ]),
               query(':enter', [style({ left: '-100%' })]),
               query(':leave', animateChild()),
               group([
                   query(':leave', [animate('400ms ease-out', style({ left: '100%' }))]),
                   query(':enter', [animate('400ms ease-out', style({ left: '0%' }))])
               ]),
               query(':enter', animateChild())
           ]),
           transition('ComponentC => ComponentA', [
               style({ position: 'relative' }),
               query(':enter, :leave', [
                   style({
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: '100%'
                   })
               ]),
               query(':enter', [style({ left: '-200%' })]),
               query(':leave', animateChild()),
               group([
                   query(':leave', [animate('400ms ease-out', style({ left: '100%' }))]),
                   query(':enter', [animate('400ms ease-out', style({ left: '0%' }))])
               ]),
               query(':enter', animateChild())
           ])
       ]);
