import { AccountSortPipe } from './appcloud-login/appcloud-credentials/account-sort.pipe';
import { ActionSuccessWithDismissComponent } from './snack-bars/action-success-with-dismiss/action-success-with-dismiss.component';
import { AppcloudCredentialsComponent } from './appcloud-login/appcloud-credentials/appcloud-credentials.component';
import { AppcloudLoginComponent } from './appcloud-login/appcloud-login.component';
import { AppReportComponent } from './app-report/app-report.component';
import { BreadcrumbListComponent } from './breadcrumb-list/breadcrumb-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangeConfigWarningComponent } from './dialogs/change-config-warning/change-config-warning.component';
import { CommonModule } from '@angular/common';
import { CredentialsModule } from './credentials/credentials.module';
import { DatePickerComponent } from './app-report/date-picker/date-picker.component';
import { DeleteWarningComponent } from './dialogs/delete-warning/delete-warning.component';
import { DeleteWarningWithDependenciesComponent } from './dialogs/delete-warning-with-dependencies/delete-warning-with-dependencies.component';
import { DividerComponent } from './divider/divider.component';
import { EditorAccordionComponent } from './editor-accordion/editor-accordion.component';
import { EloquaCredentialsComponent } from './eloqua-credentials/eloqua-credentials.component';
import { EloquaDataPriorityComponent } from './eloqua-data-priority/eloqua-data-priority.component';
import { EloquaIconComponent } from './eloqua-icon/eloqua-icon.component';
import { EloquaSyncActionsComponent } from './eloqua-sync-actions/eloqua-sync-actions.component';
import { EloquaSyncActionsMapperComponent } from './eloqua-sync-actions/eloqua-sync-actions-mapper/eloqua-sync-actions-mapper.component';
import { EloquaSyncActionsMapperRowComponent } from './eloqua-sync-actions/eloqua-sync-actions-mapper/eloqua-sync-actions-mapper-row/eloqua-sync-actions-mapper-row.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericInformationalComponent } from './dialogs/generic-informational/generic-informational.component';
import { GenericWarningWithDependenciesComponent } from './dialogs/generic-warning-with-dependencies/generic-warning-with-dependencies.component';
import { GoogleDriveCredentialsComponent } from './google-drive-credentials/google-drive-credentials.component';
import { GroupingLogicComplexComponent } from './grouping-logic-complex/grouping-logic-complex.component';
import { GroupingLogicSimpleComponent } from './grouping-logic-simple/grouping-logic-simple.component';
import { HeaderComponent } from './header/header.component';
import { HttpErrorSnackBarComponent } from './snack-bars/http-error/http-error-snack-bar.component';
import { InstructionsModule } from './instructions/instructions.module';
import { LicenseExpiredComponent } from './snack-bars/license-expired/license-expired.component';
import { LicenseInactiveComponent } from './dialogs/license-inactive/license-inactive.component';
import { LicenseNotFoundComponent } from './dialogs/license-not-found/license-not-found.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { PicklistDialogComponent } from './dialogs/picklist-dialog/picklist-dialog.component';
import { PipesModule } from '../pipes/pipes.module';
import { RedshiftDatabaseConfigurationComponent } from './redshift-database-configuration/redshift-database-configuration.component';
import { ReportChartComponent } from './app-report/report-chart/report-chart.component';
import { ReportTableComponent } from './app-report/report-table/report-table.component';
import { SaveProgramWarningComponent } from './dialogs/save-program-warning/save-program-warning.component';
import { SaveSuccessSnackBarComponent } from './snack-bars/save-success/save-success-snack-bar.component';
import { SeperateByCommaPipe } from './app-report/report-table/seperate-by-comma.pipe';
import { SftpConfigurationComponent } from './sftp-configuration/sftp-configuration.component';
import { SftpFileNamingComponent } from './sftp-file-naming/sftp-file-naming.component';
import { SidenavModule } from './sidenav/sidenav.module';
import { SnackBarsComponent } from './snack-bars/snack-bars.component';
import { SupportInformationComponent } from './support-information/support-information.component';
import { SwitchAccountComponent } from './dialogs/switch-account/switch-account.component';
import { SyncLogsTableComponent } from './app-report/sync-logs-table/sync-logs-table.component';
import { SyncRejectsTableComponent } from './app-report/sync-rejects-table/sync-rejects-table.component';
import { TitledContainerComponent } from './titled-container/titled-container.component';
import { TypeaheadSearchComponent } from './typeahead-search/typeahead-search.component';
import { UnknownContactWarningComponent } from './dialogs/unknown-contact-warning/unknown-contact-warning.component';
import { AuthorizedEloquaUsersModule } from './authorized-eloqua-users/authorized-eloqua-users.module';
import { FirefoxCookiesWarningModule } from './dialogs/firefox-cookies-warning/firefox-cookies-warning.module';
import { FieldModule } from './mapper-shared/field.module';
import { FormSectionComponent } from './form-section/form-section.component';
import { DialogFooterActionsComponent } from './dialog-footer-actions/dialog-footer-actions.component';
import { ValidationSuccessSnackBarComponent } from './snack-bars/validation-success/validation-success-snack-bar.component';
import { ValidationFailureSnackBarComponent } from './snack-bars/validation-failure/validation-failure-snack-bar.component';
import { AlertsComponent } from './alerts/alerts.component';
import { SchedulerModule } from './scheduler/scheduler.module';
import { GenericYesNoComponent } from './dialogs/generic-yes-no/generic-yes-no.component';
import { GenericBlockingComponent } from './dialogs/generic-blocking/generic-blocking.component';
import { FlexInstructionsAndContentContainerComponent } from './flex-instructions-and-content-container/public_api';
import { UtilFormPreviewComponent } from './utility/public_api';

// Standalone components
//     Import the component and then export the component.
//     Also, export the components in the component `public_api.ts` .
//     https://angular.io/guide/standalone-components#standalone-components-for-library-authors
@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FlexLayoutModule,
        FontAwesomeModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        NgxChartsModule,
        NgxMaterialTimepickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PipesModule,
        ReactiveFormsModule,
        SidenavModule,
        MatDatepickerModule,
        UtilFormPreviewComponent,
        FlexInstructionsAndContentContainerComponent
    ],
    declarations: [
        AccountSortPipe,
        ActionSuccessWithDismissComponent,
        AlertsComponent,
        AppcloudCredentialsComponent,
        AppcloudLoginComponent,
        AppReportComponent,
        BreadcrumbListComponent,
        ChangeConfigWarningComponent,
        DatePickerComponent,
        DialogFooterActionsComponent,
        DeleteWarningComponent,
        DeleteWarningWithDependenciesComponent,
        DividerComponent,
        EditorAccordionComponent,
        EloquaCredentialsComponent,
        EloquaDataPriorityComponent,
        EloquaIconComponent,
        EloquaSyncActionsComponent,
        EloquaSyncActionsMapperComponent,
        EloquaSyncActionsMapperRowComponent,
        FormSectionComponent,
        GenericInformationalComponent,
        GenericWarningWithDependenciesComponent,
        GenericYesNoComponent,
        GenericBlockingComponent,
        GenericBlockingComponent,
        GoogleDriveCredentialsComponent,
        GroupingLogicComplexComponent,
        GroupingLogicSimpleComponent,
        HeaderComponent,
        HttpErrorSnackBarComponent,
        LicenseExpiredComponent,
        LicenseInactiveComponent,
        LicenseNotFoundComponent,
        LoadingSpinnerComponent,
        PicklistDialogComponent,
        RedshiftDatabaseConfigurationComponent,
        ReportChartComponent,
        ReportTableComponent,
        SaveProgramWarningComponent,
        SaveSuccessSnackBarComponent,
        SeperateByCommaPipe,
        SftpConfigurationComponent,
        SftpFileNamingComponent,
        SnackBarsComponent,
        SupportInformationComponent,
        SwitchAccountComponent,
        SyncLogsTableComponent,
        SyncRejectsTableComponent,
        TitledContainerComponent,
        TypeaheadSearchComponent,
        UnknownContactWarningComponent,
        ValidationSuccessSnackBarComponent,
        ValidationFailureSnackBarComponent
    ],
    exports: [
        ActionSuccessWithDismissComponent,
        AppcloudCredentialsComponent,
        AppcloudLoginComponent,
        AppReportComponent,
        AuthorizedEloquaUsersModule,
        BreadcrumbListComponent,
        ChangeConfigWarningComponent,
        CredentialsModule,
        DatePickerComponent,
        DeleteWarningComponent,
        DeleteWarningWithDependenciesComponent,
        DividerComponent,
        EditorAccordionComponent,
        EloquaCredentialsComponent,
        EloquaDataPriorityComponent,
        EloquaIconComponent,
        EloquaSyncActionsComponent,
        EloquaSyncActionsMapperComponent,
        EloquaSyncActionsMapperRowComponent,
        FirefoxCookiesWarningModule,
        GenericInformationalComponent,
        GenericWarningWithDependenciesComponent,
        GoogleDriveCredentialsComponent,
        GroupingLogicComplexComponent,
        GroupingLogicSimpleComponent,
        HeaderComponent,
        HttpErrorSnackBarComponent,
        InstructionsModule,
        LicenseExpiredComponent,
        LicenseInactiveComponent,
        LicenseNotFoundComponent,
        LoadingSpinnerComponent,
        PicklistDialogComponent,
        RedshiftDatabaseConfigurationComponent,
        ReportChartComponent,
        ReportTableComponent,
        SaveProgramWarningComponent,
        SaveProgramWarningComponent,
        SaveSuccessSnackBarComponent,
        SftpConfigurationComponent,
        SftpFileNamingComponent,
        SidenavModule,
        SnackBarsComponent,
        SupportInformationComponent,
        SwitchAccountComponent,
        SyncLogsTableComponent,
        SyncRejectsTableComponent,
        TitledContainerComponent,
        TypeaheadSearchComponent,
        FieldModule,
        FormSectionComponent,
        DialogFooterActionsComponent,
        ValidationSuccessSnackBarComponent,
        ValidationFailureSnackBarComponent,
        SchedulerModule,
        AlertsComponent,
        UtilFormPreviewComponent,
        FlexInstructionsAndContentContainerComponent
    ]
})
export class ComponentsModule {}
