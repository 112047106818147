<!-- Keep in mind that this is directly in a mat-sidenav, and that sidenav isn't included
    in this template because mat-sidenav elements DO NOT like being broken into levels -->
<div>
    <form [formGroup]="patchAccountsFormGroup" (ngSubmit)="onSubmit()">
        <div class="form-field">
            <label><b>Account Name</b></label>
            <p>
                <mat-form-field appearance="fill">
                    <mat-error *ngIf="showError('name', 'required')">Account Name required</mat-error>
                    <mat-icon matPrefix>account_box</mat-icon>
                    <mat-label>Edit Account Name</mat-label>
                    <input matInput [formControl]="patchAccountsFormGroup.controls.name" />
                </mat-form-field>
            </p>
        </div>
        <div class="form-field">
            <label><b>Description</b></label>
            <p>
                <mat-form-field appearance="fill">
                    <mat-icon matPrefix>description</mat-icon>
                    <mat-label>Edit Description</mat-label>
                    <textarea matInput [formControl]="patchAccountsFormGroup.controls.description"></textarea>
                </mat-form-field>
            </p>
        </div>
        <div class="form-field">
            <label><b>SFDC Account</b></label>
            <p>
                <mat-form-field appearance="fill">
                    <mat-icon matPrefix>wb_cloudy</mat-icon>
                    <mat-label>Edit SFDC Account</mat-label>
                    <input matInput [formControl]="patchAccountsFormGroup.controls.sfAccountId" />
                </mat-form-field>
            </p>
        </div>

        <div>
            <button mat-raised-button color="primary">Save</button>
        </div>
    </form>
</div>
