export class EloquaConstants {
    static CampaignElementTypes = class {
        static CampaignAddToCampaignAction = "CampaignAddToCampaignAction";
        static CampaignAddToContactListAction = "CampaignAddToContactListAction";
        static CampaignAddToProgramBuilderAction = "CampaignAddToProgramBuilderAction";
        static CampaignCloudAction = "CampaignCloudAction";
        static CampaignCloudFeeder = "CampaignCloudFeeder";
        static CampaignCloudRule = "CampaignCloudRule";
        static CampaignContactFieldComparisonRule = "CampaignContactFieldComparisonRule";
        static CampaignContactFilterMembershipRule = "CampaignContactFilterMembershipRule";
        static CampaignContactListMembershipRule = "CampaignContactListMembershipRule";
        static CampaignCustomObjectFieldComparisonRule = "CampaignCustomObjectFieldComparisonRule";
        static CampaignDateComparisonRule = "CampaignDateComparisonRule";
        static CampaignEmail = "CampaignEmail";
        static CampaignEmailClickthroughRule = "CampaignEmailClickthroughRule";
        static CampaignEmailOpenedRule = "CampaignEmailOpenedRule";
        static CampaignEmailSentRule = "CampaignEmailSentRule";
        static CampaignForm = "CampaignForm";
        static CampaignLandingPage = "CampaignLandingPage";
        static CampaignMoveToCampaignAction = "CampaignMoveToCampaignAction";
        static CampaignMoveToContactListAction = "CampaignMoveToContactListAction";
        static CampaignMoveToProgramBuilderAction = "CampaignMoveToProgramBuilderAction";
        static CampaignRemoveFromContactListAction = "CampaignRemoveFromContactListAction";
        static CampaignSegment = "CampaignSegment"
        static CampaignSubmitFormRule = "CampaignSubmitFormRule";
        static CampaignWaitAction = "CampaignWaitAction";
        static CampaignWebsiteVisitRule = "CampaignWebsiteVisitRule";
        static CanvasAddToProgramAction = "CanvasAddToProgramAction";
        static CanvasMoveToProgramAction = "CanvasMoveToProgramAction";
        static CanvasPercentageSplitRule = "CanvasPercentageSplitRule";
        static CanvasRunUpdateRuleSet = "CanvasRunUpdateRuleSet";
    }

    static TrackingQueryParams = class {
        static ELQ_DEFAULT_TRACKING_PARAM_NAME: string = 'elqTrack';
        static ELQ_ALT_TRACKING_PARAM_S = 's';
        static ELQ_ALT_TRACKING_PARAM_S_VALUE = '<span class=eloquaemail>siteid</span>';
        static ELQ_ALT_TRACKING_PARAM_ELQ = 'elq';
        static ELQ_ALT_TRACKING_PARAM_ELQ_VALUE = '<span class=eloquaemail>recipientid</span>';
    }

    static maxTokenLifeMilliseconds = 28800000; // 8 hours
}