import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from '../../services/error-handling/error-handling.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { HttpHeaderConstants } from '../../services/http-header.constant';
import { Account } from '../../components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { CountResponse } from '../../models/appcloud/count-response';
import { NameCountUtilsService } from './name-count-util.service';

@Injectable({
    providedIn: 'root'
})
export class AccountsService {
    private ACCOUNTS_URL: string = environment.publicApiHost + '/appcloud/accounts';

    constructor(
        private errorHandlingService: ErrorHandlingService,
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private nameCountUtilsService: NameCountUtilsService
    ) {}

    get(additionalHeaders: any = {}): Observable<Account[]> {
        this.spinnerService.addProcess('AccountService.get', 'Pulling up your AppCloud account information...');

        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders })
        };

        return this.http.get<Account[]>(this.ACCOUNTS_URL, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving account data from Relationship One. Please try again later.'
                );
                return of<Account[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AccountService.get'))
        );
    }

    getAccountsCount(
        createdAtdate: string,
        operator: string,
        ownershipOperator: string,
        ownershipValue: string
    ): Observable<CountResponse[]> {
        this.spinnerService.addProcess('AccountService.getAccountsCount', 'Loading accounts count...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };

        let queryUrl =
            `${this.ACCOUNTS_URL}/counts?` +
            this.nameCountUtilsService.makeParams(operator, createdAtdate, ownershipOperator, ownershipValue) +
            'queryScope=all';

        return this.http.get<CountResponse[]>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving your cloud user info. Please try again later.'
                );
                return of<CountResponse[]>();
            }),
            finalize(() => this.spinnerService.completeProcess('AccountService.getAccountsCount'))
        );
    }
}
