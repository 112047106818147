import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ErrorHandlingService } from '../../services/error-handling/error-handling.service';
import { environment } from '../../environments/environment';
import { Redirect } from '../../models/eloqua';

const headers = new HttpHeaders({
    'Content-Type': 'application/json'
});

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    private OAUTH_URL: string = environment.publicApiHost + '/eloqua/oauth2/authorize';

    constructor(private errorHandlingService: ErrorHandlingService, private http: HttpClient) {}

    authorize(globalAppId: string, oauthId: number, redirectUri: string): Observable<Redirect> {
        const customHttpOptions = {
            headers: headers,
            params: new HttpParams({
                fromObject: {
                    globalAppId: globalAppId,
                    oauthId: JSON.stringify(oauthId),
                    redirectUri: redirectUri
                }
            })
        };

        return this.http.get<Redirect>(this.OAUTH_URL, customHttpOptions).pipe(
            catchError(error => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while redirecting to Eloqua. Please try again later.'
                );
                return of<Redirect>();
            })
        );
    }

    authorizeForInstall(installId: string, oauthId: number, redirectUri: string): Observable<Redirect> {
        const customHttpOptions = {
            headers: headers,
            params: new HttpParams({
                fromObject: {
                    installId: installId,
                    oauthId: JSON.stringify(oauthId),
                    redirectUri: redirectUri
                }
            })
        };

        return this.http.get<Redirect>(this.OAUTH_URL, customHttpOptions).pipe(
            catchError(error => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while redirecting to Eloqua. Please try again later.'
                );
                return of<Redirect>();
            })
        );
    }
}
