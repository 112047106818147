import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';

import { NavLink } from 'projects/internal/r1-component-library/src/lib/components/sidenav/models/nav-link.model';
import { CloudUser } from 'projects/internal/r1-component-library/src/lib/models/appcloud/cloud-user.model';
import { AuthenticationPayload } from 'projects/internal/r1-component-library/src/lib/models/oauth-token.model';
import { CloudUserState } from '../../../states/cloud-user.state';

@Component({
    selector: 'r1-user-menu',
    template: `
        <div id="profile-preview">
            <button mat-icon-button [matMenuTriggerFor]="rootMenu" id="profile-icon">
                {{ cloudUser.firstname | slice : 0 : 1 | uppercase }}
                {{ cloudUser.lastname | slice : 0 : 1 | uppercase }}
            </button>

            <mat-menu #rootMenu="matMenu" backdropClass="backdrop-hack">
                <div class="user-menu-backdrop">
                    <div id="preview-user-info">
                        <p id="preview-name">
                            {{ cloudUser.firstname | titlecase }} {{ cloudUser.lastname | titlecase }}
                        </p>
                        <p id="account-number">ACCT #</p>
                    </div>
                    <mat-divider></mat-divider>
                    <div *ngFor="let link of userPreviewMenuLinks" id="menu-links">
                        <span *ngIf="link.path.includes('.'); else routerlink">
                            <a mat-menu-item href="{{ link.path }}" target="_blank">
                                {{ link.name }}
                            </a>
                        </span>
                        <ng-template #routerlink>
                            <button mat-menu-item [routerLink]="link.path">
                                {{ link.name }}
                            </button>
                        </ng-template>
                    </div>
                </div>
            </mat-menu>
        </div>
    `,
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
    @Input() userPayload: AuthenticationPayload;
    private subscriptions: Subscription[] = [];

    constructor() {}

    userPreviewMenuLinks: Array<NavLink> = [
        { name: 'Profile', path: 'profile/manage', dividerBelow: true },
        { name: 'Switch Account', path: 'profile/switch-account', dividerBelow: true },
        { name: 'Log Out', path: 'profile/logout' }
    ];

    @Select(CloudUserState.cloudUser) cloudUser$: Observable<CloudUser>;
    public cloudUser: CloudUser = null;

    ngOnInit() {
        this.subscriptions.push(this.cloudUser$.subscribe((cloudUsr) => (this.cloudUser = cloudUsr)));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
