// Core Angular + misc modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';

// Models
import { LoginForm } from '../models/login-form.model';
import { PatchAccountsForm } from '../models/patch-accounts-form.model';
import { MetricsCard } from '../models/metrics-card.model';

// Feature & routing modules
import { HomeModule } from './appcloud-admin/home/home.module';
import { ToolsModule } from './appcloud-admin/tools/tools.module';
import { AppcloudLoginModule } from './appcloud-login/appcloud-login.module';
import { AppcloudAdminRoutingModule } from './appcloud-admin/appcloud-admin-routing.module';

// In-project components
import { AppcloudAdminComponent } from './appcloud-admin/appcloud-admin.component';
import { NotFoundComponent } from './not-found/not-found.component';

// Out-of-project shared modules
import { R1ComponentLibraryModule } from 'projects/internal/r1-component-library/src/public_api';

// Misc
import { FaIconsModule } from '../fa-icons.module';

@NgModule({
    declarations: [AppcloudAdminComponent, NotFoundComponent],
    imports: [
        CommonModule,
        MaterialModule,
        HomeModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        R1ComponentLibraryModule,
        AppcloudAdminRoutingModule,
        LayoutModule,
        FaIconsModule,
        ToolsModule,
        AppcloudLoginModule
    ],
    providers: [LoginForm, MetricsCard, PatchAccountsForm]
})
export class ComponentsModule {}
