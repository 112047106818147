import { Observable } from 'rxjs/internal/Observable';

export interface AppcloudCredentialsLoginInterface {
    submitLoginForm(credential: AppcloudCredential): void;
    isLoading$: Observable<boolean>;
    loadingMessages$: Observable<string[]>;
    accounts: Account[];
    loginError: LoginError;
}

export class AppcloudCredential {
    username: string;
    email?: string;
    password: string;
    accountId?: number;
}

export class Account {
    id?: number;
    name?: string;

    // TODO: right spot for these post 7695 merging in master?
    description?: string;
    sfAccountId?: string;
    createdAt?: any; //TODO: TYPE
    updatedAt?: any; //TODO: type
}

export class LoginError {
    error: {
        error: string;
        error_description: string;
        failedAttempts?: string;
    };
}
