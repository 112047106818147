import { Component } from '@angular/core';

@Component({
    selector: 'r1-appcloud-login',
    template: `
        <div class="login-background">
            <div class="flex-container">
                <r1-login-form></r1-login-form>
                <r1-login-welcome></r1-login-welcome>
            </div>
        </div>
    `,
    styleUrls: ['./appcloud-login.component.scss']
})
export class AppCloudLoginComponent {
    constructor() {}
}
