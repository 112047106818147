import { Component } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'r1-footer',
    template: `
        <div class="footer-container">
            <p id="footer-text">
                © {{ thisYear }} Relationship One, LLC | All rights reserved |
                <a href="https://twitter.com/relationshipone" target="_blank"
                    ><fa-icon [icon]="['fab', 'twitter']"></fa-icon></a
                >&nbsp;

                <a href="https://www.facebook.com/RelationshipOne" target="_blank">
                    <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
                </a>
            </p>
        </div>
    `,
    styles: [
        `
            .footer-container {
                justify-content: flex-end;
                align-content: flex-end;
                width: 100%;
                background-color: var(--r1-very-dark-blue);
                height: 40px;
                position: relative;
            }

            #footer-text {
                color: #fff;
                padding-left: 1rem;
                margin-top: 0;
                margin-bottom: 0;

                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                fa-icon {
                    color: #fff;
                }
            }
        `
    ]
})
export class FooterComponent {
    constructor(library: FaIconLibrary) {
        library.addIcons(faTwitter, faFacebookF);
    }

    thisYear = new Date().getFullYear();
}
