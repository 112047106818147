import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppcloudAdminComponent } from './components/appcloud-admin/appcloud-admin.component';
import { AppCloudLoginComponent } from './components/appcloud-login/appcloud-login.component';

// This is a life saving article. If you make ajustments to route hierarchy and nesting, you need this aricle.
// https://www.freakyjolly.com/angular-nested-routing-with-multiple-routeroutlet-using-loadchildren-having-own-router-modules-example-application/
const routes: Routes = [
    { path: 'login', component: AppCloudLoginComponent },
    {
        path: 'admin',
        component: AppcloudAdminComponent,
        loadChildren: () =>
            import('./components/appcloud-admin/appcloud-admin-routing.module').then(
                (m) => m.AppcloudAdminRoutingModule
            )
    },
    { path: '**', component: AppCloudLoginComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
