import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class AuthenticationLevelService {
    private authorizationLevel: string = 'instance';

    constructor() {}

    public getAuthorizationLevel(): string {
        return this.authorizationLevel;
    }
    public setAuthorizationLevel(authorizationLevel: string) {
        this.authorizationLevel = authorizationLevel;
    }
}
