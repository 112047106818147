import { Platform } from '@angular/cdk/platform';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'r1-firefox-cookies-warning',
    template: `
        <h1 mat-dialog-title class="mat-dialog-title">Firefox Users: Attention Required</h1>
        <div mat-dialog-content>
            Firefox by default now blocks cookies like the type that manages authentication to this application.
            However, you can enable them to work for R1 applications by following this short guide:
            <br />
            <br />
            <div>
                <a href="https://docs.relationshipone.com/docs/firefox-enhanced-tracking-protection" target="_blank"
                    >https://docs.relationshipone.com/docs/firefox-enhanced-tracking-protection</a
                >
            </div>
        </div>
    `,
    styles: ['.mat-dialog-title {margin: 0}']
})
export class FirefoxCookiesWarningComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<FirefoxCookiesWarningComponent>, public platform: Platform) {}

    ngOnInit() {}
}
