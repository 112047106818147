export class AppConstants {
    static metricsDefaultDisplayConfiguration = [
        {
            title: 'Cloud Users',
            readAs: 'Cloud Users',
            about: 'Total',
            cols: 1,
            rows: 1
        },
        {
            title: 'Accounts',
            readAs: 'Accounts',
            about: 'Total',
            cols: 1,
            rows: 1
        },
        {
            title: 'App Instances',
            readAs: 'App Instances',
            about: 'Total',
            cols: 1,
            rows: 1
        },
        {
            title: 'MRR',
            about: 'Current Month',
            readAs: 'MRR',
            cols: 1,
            rows: 1
        }
    ];
}
