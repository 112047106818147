import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsModule } from '../instructions/instructions.module';
import { AuthorizedEloquaUsersComponent } from './authorized-eloqua-users.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [AuthorizedEloquaUsersComponent],
    imports: [
        CommonModule,
        InstructionsModule,
        FlexLayoutModule,
        MatIconModule,
        MatFormFieldModule,
        MatOptionModule,
        MatChipsModule,
        MatAutocompleteModule,
        ReactiveFormsModule
    ],
    exports: [AuthorizedEloquaUsersComponent]
})
export class AuthorizedEloquaUsersModule {}