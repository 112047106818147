import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Icon library approach from
//    https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/icon-library.md#icon-library-approach
@NgModule({
    declarations: [],
    imports: [CommonModule]
})
export class FaIconsModule {}
