<mat-drawer-container class="app-container">
    <mat-drawer
        #drawer
        mode="side"
        [opened]="initialOpenState"
        [@onSideNavChange]="showLinkText ? 'open' : 'close'"
        class="sidenav-container"
    >
        <r1-nav-expansion id="menu-expansion-icon" (click)="onSidenavExpansionToggle()"></r1-nav-expansion>
        <mat-list>
            <ng-container *ngFor="let linkGroup of sidenavLinksGroupingArray">
                <r1-sidenav-list [navLinkGrouping]="linkGroup"></r1-sidenav-list>
            </ng-container>
        </mat-list>
    </mat-drawer>

    <!-- Any content of the app to the right of the sidenav is projected into this humble tag -->
    <ng-content></ng-content>
</mat-drawer-container>
