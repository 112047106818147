<!-- This search exists for the sake of not angering the added
        in search logic part of the search querying/merging logic
-->
<div id="search-ahead">
    <mat-form-field appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput id="search-input" #input />
    </mat-form-field>
</div>

<div>
    <table
        [dataSource]="dataSource"
        mat-table
        matSort
        matSortDirection="asc"
        matSortDisableClear
        id="full-width-table"
        aria-label="Elements"
        (matSortChange)="sortChange($event)"
    >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-header-txt">Account Name</th>
            <td mat-cell *matCellDef="let row" class="cell-txt">{{ row.name }}</td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-header-txt">Account ID</th>
            <td mat-cell *matCellDef="let row" class="cell-txt">{{ row.id }}</td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-header-txt">Create Date</th>
            <td mat-cell *matCellDef="let row" class="cell-txt">{{ row.createdAt }}</td>
        </ng-container>

        <!-- Updated Column -->
        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-header-txt">Last Updated Date</th>
            <td mat-cell *matCellDef="let row" class="cell-txt">{{ row.updatedAt }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row-hover" (click)="editAccount(row)"></tr>
    </table>
    <mat-paginator [length]="totalElements" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
