import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'projects/internal/r1-component-library/src/lib/services/spinner/spinner.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { QueryBuilderService } from './utils/query-builder.service';
import { ErrorHandlingService } from 'projects/internal/r1-component-library/src/lib/services/error-handling';
import { AccountPagedResponse } from '../models/account-paging.model';
import { environment } from 'projects/internal/r1-component-library/src/lib/environments/environment';
import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { SearchObject } from '../models/search.model';

@Injectable({
    providedIn: 'root'
})
export class ManageAccountsService {
    constructor(
        private queryBuilder: QueryBuilderService,
        private spinnerService: SpinnerService,
        private errorHandlingService: ErrorHandlingService,
        private http: HttpClient
    ) {}

    private accountsURL: string = environment.publicApiHost + '/appcloud/accounts';

    private accountEditSaved = new Subject<void>();
    public accountEditSaved$ = this.accountEditSaved.asObservable();

    saveAccountEdit(): void {
        this.accountEditSaved.next();
    }

    findAccounts(searchObj: SearchObject): Observable<AccountPagedResponse> {
        const { page, pageSize, sortField, sortOrder } = searchObj; // searchObj.filter and not `{filter, ...}` because RxJs.filter() namespace

        let queryUrl = '';
        queryUrl += this.makeRequiredAcctsParams() + this.makeOptionalAcctsParams(sortOrder, page, pageSize, sortField);
        queryUrl += '&' + this.queryBuilder.searchAccountsQueryBuilder(searchObj.filter);

        const processName = 'ManageAccountService.loadAccounts';
        this.spinnerService.addProcess(processName, 'Loading accounts...');

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };

        return this.http.get<AccountPagedResponse>(queryUrl, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while retrieving accounts info. Please try again later.'
                );
                return of<AccountPagedResponse>();
            }),
            finalize(() => this.spinnerService.completeProcess(processName))
        );
    }

    patchAccount(id: number, account: Account): Observable<Account> {
        const processName = 'ManageAccountService.patchAccount';
        this.spinnerService.addProcess(processName, 'Updating your configuration...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/merge-patch+json',
                Accept: 'application/json'
            })
        };

        let query = `${this.accountsURL}/${id}?queryScope=all`;

        return this.http.patch<Account>(query, account, httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while sending data to Relationship One. Please try again later.'
                );
                return of<Account>();
            }),
            finalize(() => this.spinnerService.completeProcess(processName))
        );
    }

    private makeRequiredAcctsParams(): string {
        let query = '';
        query += `${this.accountsURL}?`;
        query += 'responseType=paged' + '&queryScope=all&';
        return query;
    }

    private makeOptionalAcctsParams(sortOrder?: string, page?: number, pageSize?: number, sortField?: string): string {
        let query = '&';

        if (page) {
            query += `page=${page.toString()}&`;
        }
        if (pageSize) {
            query += `pageSize=${pageSize.toString()}&`;
        }
        if (sortField) {
            query += `sortField=${sortField}&`;
        }
        if (sortOrder) {
            query += `sortOrder=${sortOrder}&`;
        }

        if (query.endsWith('&')) {
            query = query.slice(0, -1);
        }
        return query;
    }
}
