import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleSettingsComponent } from './schedule-settings/schedule-settings.component';
import { InstructionsModule } from '../instructions/instructions.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { SchedulerLiteComponent } from './scheduler-lite/scheduler-lite.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { ToggleableSchedulerComponent } from './toggleable-scheduler/toggleable-scheduler.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [SchedulerLiteComponent, SchedulerComponent, ScheduleSettingsComponent, ToggleableSchedulerComponent],
    imports: [
        CommonModule,
        InstructionsModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [SchedulerLiteComponent, SchedulerComponent, ScheduleSettingsComponent, ToggleableSchedulerComponent]
})
export class SchedulerModule {}
