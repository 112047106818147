import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { ManageAccountsService } from './manage-accounts.service';
import { Account } from 'projects/internal/r1-component-library/src/lib/components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { SearchObject } from '../models/search.model';
import { AccountPagedResponse } from '../models/account-paging.model';

/**
 * Model's article on how this DataSource works https://blog.angular-university.io/angular-material-data-table/#whynotusemattabledatasource
 *
 * Model followed code: https://github.com/angular-university/angular-material-course/blob/2-data-table-finished/src/app/services/lessons.datasource.ts
 *
 * * Per the ngMat docs on [ckdTable](https://v8.material.angular.io/cdk/table/api#CdkTable)
 *
 * > "When providing a DataSource object:
 * >
 * >the table will use the Observable stream
 * > provided by the `connect() function` and trigger updates when that stream emits new data
 * > array values.
 * >
 * > During the table's `ngOnDestroy` or when the data source is removed from the
 * > table, the table will call the DataSource's `disconnect() function` (may be useful for cleaning
 * > up any subscriptions registered during the connect process)."
 */
export class AccountsDataSource implements DataSource<Account> {
    public accountsSubject$ = new Subject<Account[]>();
    public totalElementsSubject$ = new Subject<number>();

    constructor(private accountsService: ManageAccountsService) {}

    loadAccounts(searchObj: SearchObject) {
        this.accountsService.findAccounts(searchObj).subscribe((accounts: AccountPagedResponse) => {
            this.totalElementsSubject$.next(accounts.totalElements);
            this.accountsSubject$.next(accounts.content);
        });
    }

    connect(collectionViewer: CollectionViewer): Observable<Account[]> {
        return this.accountsSubject$.asObservable().pipe();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.accountsSubject$.complete();
        this.totalElementsSubject$.complete();
    }
}
