import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ErrorHandlingService } from '../../services/error-handling';
import { SpinnerService } from '../../services/spinner';
import { OauthToken, AuthenticationPayload } from '../../models';
import { AuthenticationLevelService } from './authentication-level.service';
import { LoginError } from '../../components/appcloud-login/appcloud-credentials/appcloud-credentials.model';
import { Platform } from '@angular/cdk/platform';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FirefoxCookiesWarningComponent } from '../../components/dialogs/firefox-cookies-warning/firefox-cookies-warning.component';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private LOGIN_URL: string = environment.publicApiHost + '/oauth/token';
    private AGENT_TOKEN_URL: string = environment.agentHost + '/public-api/token';
    private APPCLOUD_TOKEN_URL: string = environment.appCloudHost + '/public-api/token';

    constructor(
        private cookieService: CookieService,
        private http: HttpClient,
        private errorHandlingService: ErrorHandlingService,
        private spinnerService: SpinnerService,
        private authenticationLevelService: AuthenticationLevelService,
        private platform: Platform,
        private dialog: MatDialog
    ) {}

    loginAppCloudAdmin(email: string, password: string): Observable<OauthToken> {
        this.spinnerService.addProcess('AuthenticationService.login', 'Logging in...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };
        let body: URLSearchParams = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('scope', 'ui');
        body.set('username', email);
        body.set('password', password);

        return this.http.post<OauthToken>(this.LOGIN_URL, body.toString(), httpOptions).pipe(
            // OauthToken is an oauth endpoint response, so the phrasing is not appropriate. We should rename it `ResponseObject`
            // AuthenticationPayload is model of the claims, the model of the jwt

            tap((oauthToken) => {
                // JWT may be out of date
                this.deleteJwtCookies();

                if (
                    !this.payloadHasRole('ROLE_ADMIN', this.getPayload(oauthToken.access_token)) &&
                    !this.payloadHasRole('ROLE_SUPPORT', this.getPayload(oauthToken.access_token))
                ) {
                    throw new Error('Error: Unauthorized access');
                }
                this.setJwtCookies(oauthToken);
            }),
            catchError((error) => {
                return throwError(error);
            }),
            finalize(() => this.spinnerService.completeProcess('AuthenticationService.login'))
        );
    }

    login(
        username: string,
        password: string,
        accountId?: number,
        eloquaUser?: string,
        installId?: string
    ): Observable<OauthToken> {
        this.spinnerService.addProcess('AuthenticationService.login', 'Logging in...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };
        let body: URLSearchParams = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('scope', 'ui');
        body.set('username', username);
        body.set('password', password);
        if (accountId) {
            body.set('accountId', accountId.toString());
        }
        if (eloquaUser) {
            body.set('eloquaUser', eloquaUser);
        }
        if (installId) {
            body.set('installId', installId);
        }

        return this.http.post<OauthToken>(this.LOGIN_URL, body.toString(), httpOptions).pipe(
            tap((oauthToken) => {
                this.setJwtCookies(oauthToken);
            }),
            catchError((error: LoginError) => {
                return throwError(error);
            }),
            finalize(() => this.spinnerService.completeProcess('AuthenticationService.login'))
        );
    }

    logout(): void {
        this.deleteJwtCookies();
        this.spinnerService.clearProcesses();
    }

    refreshToken(refreshToken: string): Observable<OauthToken> {
        this.spinnerService.addProcess('AuthenticationService.refreshToken', 'Checking your credentials...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            })
        };
        let body: URLSearchParams = new URLSearchParams();
        body.set('grant_type', 'refresh_token');
        body.set('scope', 'ui');
        body.set('refresh_token', refreshToken);

        const payload: AuthenticationPayload = this.getPayload(refreshToken);
        if (payload.accountId) {
            body.set('accountId', payload.accountId.toString());
        }
        if (payload.eloquaUser) {
            body.set('eloquaUser', payload.eloquaUser);
        }

        return this.http.post<OauthToken>(this.LOGIN_URL, body.toString(), httpOptions).pipe(
            tap((oauthToken) => {
                this.setJwtCookies(oauthToken);
            }),
            catchError(() => {
                return of<OauthToken>();
            }),
            finalize(() => this.spinnerService.completeProcess('AuthenticationService.refreshToken'))
        );
    }

    // store the URL so we can redirect after logging in
    redirectUrl: string | null = null;

    isAuthenticated(): boolean {
        if (!this.cookieService.check('jwt-access')) {
            return false;
        }
        const payload = this.getPayload(this.cookieService.get('jwt-access'));
        if (payload.exp && payload.exp * 1000 >= Date.now()) {
            return true;
        }
        if (!this.cookieService.check('jwt-refresh')) {
            return false;
        }
        const refreshPayload = this.getPayload(this.cookieService.get('jwt-refresh'));
        if (refreshPayload.exp && refreshPayload.exp * 1000 >= Date.now()) {
            return true;
        }
        return false;
    }

    /**
     * @description Checks if a user cannot authenticate because of Firefox's cookie policy
     */
    passesFirefoxCookieValidation(): boolean {
        if (!this.isAuthenticated()) {
            if (this.platform.FIREFOX) {
                this.dialog.open(FirefoxCookiesWarningComponent, {
                    width: '400px',
                    disableClose: true
                });

                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    isAuthorized(): any {
        const payload = this.getAccessTokenPayload();

        if (!this.payloadHasRole('ROLE_ADMIN', payload) && !this.payloadHasRole('ROLE_SUPPORT', payload)) {
            return false;
        } else {
            return true;
        }
    }

    isInstallAuthenticated(): boolean {
        if (!this.cookieService.check('jwt-install-access')) {
            return false;
        }
        const payload = this.getPayload(this.cookieService.get('jwt-install-access'));
        if (payload.exp && payload.exp * 1000 >= Date.now()) {
            return true;
        }
        if (!this.cookieService.check('jwt-install-refresh')) {
            return false;
        }
        const refreshPayload = this.getPayload(this.cookieService.get('jwt-install-refresh'));
        if (refreshPayload.exp && refreshPayload.exp * 1000 >= Date.now()) {
            return true;
        }
        return false;
    }

    hasRole(role: string): boolean {
        let payload;
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            payload = this.getPayload(this.cookieService.get('jwt-install-access'));
        } else {
            payload = this.getPayload(this.cookieService.get('jwt-access'));
        }

        return this.payloadHasRole(role, payload);
    }

    payloadHasRole(role: string, payload: AuthenticationPayload) {
        return payload.authorities && payload.authorities.findIndex((authority) => authority === role) >= 0;
    }

    oauthPayloadHasRole(role: string, payload: OauthToken) {
        return payload.authorities && payload.authorities.findIndex((authority) => authority === role) >= 0;
    }

    getTokenFromAgentSession() {
        this.spinnerService.addProcess(
            'AuthenticationService.getTokenFromAgentSession',
            'Checking your credentials...'
        );
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json'
            }),
            withCredentials: true
        };

        return this.http.get<OauthToken>(this.AGENT_TOKEN_URL, httpOptions).pipe(
            tap((oauthToken) => {
                this.setJwtCookies(oauthToken);
            }),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'Authentication failed. Please try again later.'
                );
                return of<OauthToken>();
            }),
            finalize(() => this.spinnerService.completeProcess('AuthenticationService.getTokenFromAgentSession'))
        );
    }

    getTokenFromAppcloudSession() {
        this.spinnerService.addProcess(
            'AuthenticationService.getTokenFromAppcloudSession',
            'Checking your credentials...'
        );
        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json'
            }),
            withCredentials: true
        };

        return this.http.get<OauthToken>(this.APPCLOUD_TOKEN_URL, httpOptions).pipe(
            tap((oauthToken) => {
                this.setJwtCookies(oauthToken);
            }),
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'Authentication failed. Please try again later.'
                );
                return of<OauthToken>();
            }),
            finalize(() => this.spinnerService.completeProcess('AuthenticationService.getTokenFromAppcloudSession'))
        );
    }

    getAccessTokenPayload(): AuthenticationPayload {
        let jwtAccess;
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            jwtAccess = this.cookieService.get('jwt-install-access');
        } else {
            jwtAccess = this.cookieService.get('jwt-access');
        }
        return this.getPayload(jwtAccess);
    }

    getRefreshTokenExpiration(refreshToken?: string): Date {
        let jwtRefresh: string;
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            jwtRefresh = refreshToken || this.cookieService.get('jwt-install-access');
        } else {
            jwtRefresh = refreshToken || this.cookieService.get('jwt-access');
        }
        const payload: AuthenticationPayload = this.getPayload(jwtRefresh);
        return new Date(payload.exp * 1000);
    }

    getPayload(jwt: string): AuthenticationPayload {
        if (jwt) {
            const encodedPayload = jwt.split('.')[1];
            const payload = window.atob(encodedPayload);
            return JSON.parse(payload);
        }
        return {};
    }

    setJwtCookies(oauthToken: OauthToken) {
        const expiration = this.getRefreshTokenExpiration(oauthToken.refresh_token);
        const sameSite = environment.local ? null : 'None';
        const secure = !environment.local;
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            this.cookieService.set(
                'jwt-install-access',
                oauthToken.access_token,
                expiration,
                '/',
                null,
                secure,
                sameSite
            );
            this.cookieService.set(
                'jwt-install-refresh',
                oauthToken.refresh_token,
                expiration,
                '/',
                null,
                secure,
                sameSite
            );
        } else {
            this.cookieService.set('jwt-access', oauthToken.access_token, expiration, '/', null, secure, sameSite);
            this.cookieService.set('jwt-refresh', oauthToken.refresh_token, expiration, '/', null, secure, sameSite);
        }
    }

    private deleteJwtCookies() {
        const sameSite = environment.local ? null : 'None';
        const secure = !environment.local;
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            this.cookieService.delete('jwt-install-access', '/');
            if (this.cookieService.check('jwt-install-access')) {
                this.cookieService.set('jwt-install-access', '', -1, '/', null, secure, sameSite);
                if (this.cookieService.check('jwt-install-access')) {
                    console.error('Failed to log out. Cookies were not deleted.');
                }
            }

            this.cookieService.delete('jwt-install-refresh', '/');
            if (this.cookieService.check('jwt-install-refresh')) {
                this.cookieService.set('jwt-install-refresh', '', -1, '/', null, secure, sameSite);
            }
        } else {
            this.cookieService.delete('jwt-access', '/');
            this.cookieService.delete('jwt-refresh', '/');
        }
    }
}
