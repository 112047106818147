import { Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { SupportService } from '../../services/support.service';
import { SupportInformation } from '../../models/support.model';
import { AppLicenseService } from '../../services/appcloud';
import { AppLicense } from '../../models/appcloud';

@Component({
    selector: 'r1-support-information',
    templateUrl: './support-information.component.html',
    styleUrls: ['./support-information.component.scss']
})
export class SupportInformationComponent implements OnInit {
    queryParams = {};
    hasStripeSubscription: boolean;

    @Input()
    data: SupportInformation;

    constructor(private supportService: SupportService, private applicenseService: AppLicenseService) {}

    ngOnInit() {
        if (this.data.globalAppId) {
            this.applicenseService.getLatest(this.data.globalAppId).subscribe((license: AppLicense) => {
                this.data.licenseEndDate = new Date(license.usageEndDate).toLocaleDateString('en-US');
                this.data.licenseId = license.id;
                this.hasStripeSubscription = !!license.subscriptionId;
            });
        }
    }

    formatDate(date: Date) {
        return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0000') + '+0000';
    }

    copyToClipboard() {
        let htmlValue = this.supportService.generateSupportInfo('html', this.data);
        let plainValue = this.supportService.generateSupportInfo('plaintext', this.data);
        this.copyToClipboardWithRichText(htmlValue, plainValue);
    }

    /**
     * We use this method of setting clipboard data because it allows for rich text.
     * @param html The HTML version of the text.
     */
    copyToClipboardWithRichText(html: string, plain: string) {
        function listener(e) {
            e.clipboardData.setData('text/html', html);
            e.clipboardData.setData('text/plain', plain);
            e.preventDefault();
        }
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }
}
