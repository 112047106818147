import { AlertCriteria, AppInstance, EloquaInstall } from '../appcloud/index';
import { TimeDelay } from '../../constants/index';
import { Alert } from './alerts.model';
import { AlertUtils } from '../../utils/index';

export interface AlertCriteriaGenerator<T> {
    generate(alert: Alert, data: T, globalId: string, timeDelay?: TimeDelay): AlertCriteria;
}

export abstract class BasicAlertCriteriaGenerator<T> implements AlertCriteriaGenerator<T> {

    constructor() {}

    generate(alert: Alert, data: T, globalId: string, timeDelay?: TimeDelay): AlertCriteria {
        let alertCriteria: AlertCriteria = new AlertCriteria();
        alertCriteria.active = true;
        this.setData(alertCriteria, this.convertData(alertCriteria, data, globalId));
        this.setAlertData(alertCriteria, alert);
        this.setTimeDelay(alertCriteria, alert, timeDelay);
        return alertCriteria;
    }

    protected setData(alertCriteria: AlertCriteria, data: {}) {
        Object.entries(data)
            .forEach(([key, value]) => alertCriteria[key] = value);
    }

    protected setAlertData(alertCriteria: AlertCriteria, alert: Alert) {
        alertCriteria.alertCategory = alert.alertCategory;
        alertCriteria.analysis = alert.analysis;
        alertCriteria.analysisCriteria = AlertUtils.combineAnalysisCriteria(alert.analysisCriteria);
        alertCriteria.severity = alert.severity;
        alertCriteria.emailtemplateName = alert.emailtemplateName;
        alertCriteria.maximumPerDay = alert.maximumPerDay;
    }

    protected setTimeDelay(alertCriteria: AlertCriteria, alert: Alert, timeDelay?: TimeDelay) {
        if (alert.includeTimeDelay && timeDelay) {
            alertCriteria.futureAlertInterval = timeDelay.interval;
            alertCriteria.futureAlertUnit = timeDelay.unit;
        }
    }

    abstract convertData(alertCriteria: AlertCriteria, data: T, globalId: string): {};
}