import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { HttpErrorSnackBarData } from '../../models/error-handling/http-error.model';
import { HttpErrorSnackBarComponent } from '../../components/snack-bars/http-error/http-error-snack-bar.component';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingService {
    constructor(private snackBarService: MatSnackBar, private spinnerService: SpinnerService) {}

    clearError() {
        this.snackBarService.dismiss();
    }

    handleHttpError(errorEvent: any, handleMultipleMessages: boolean = false, customErrorMessage: string = null): void {
        let openedSnackBarRef = this.snackBarService._openedSnackBarRef;
        let errorMessages: string[];
        let newErrorMessage = customErrorMessage ? customErrorMessage : this.buildHttpErrorMessage(errorEvent); // override default error message if needed

        if (handleMultipleMessages) {
            errorMessages = openedSnackBarRef
                ? [...openedSnackBarRef.instance.data.errorMessages, newErrorMessage]
                : [newErrorMessage]; // append all new messages to the existing snackbar
        } else {
            errorMessages = [newErrorMessage]; // set the error message to the new message
        }

        let httpErrorSnackBarData: HttpErrorSnackBarData = this.createHttpErrorSnackBarData(errorEvent, errorMessages);
        this.setSnackBarData(openedSnackBarRef, httpErrorSnackBarData);
        this.spinnerService.clearProcesses();
    }

    private buildHttpErrorMessage(errorEvent: any): string {
        switch (errorEvent.status) {
            case 403: {
                return '403 - Auth error';
            }
            case 500: {
                return '500 - Internal Server Error';
            }
            default: {
                return 'There was an error retrieving data';
            }
        }
    }

    private createHttpErrorSnackBarData(errorEvent: any, errorMessages: string[]): HttpErrorSnackBarData {
        return {
            errorEvent: errorEvent,
            errorMessages: errorMessages
        };
    }

    private setSnackBarData(openedSnackBarRef, httpErrorSnackBarData: HttpErrorSnackBarData): void {
        if (openedSnackBarRef) {
            this.snackBarService._openedSnackBarRef.instance.data = httpErrorSnackBarData;
        } else {
            this.snackBarService.openFromComponent(HttpErrorSnackBarComponent, {
                data: httpErrorSnackBarData,
                panelClass: ['error-handling-orange'],
                verticalPosition: 'top'
            });
        }
    }
}
