import { AlertConstants, TimeDelay } from '../../constants/alert.constants';
import { AppInstance, EloquaInstall } from '../appcloud/index';
import { AlertCriteria } from '../appcloud/alert/alert-criteria.model';
import { AlertCriteriaGenerator } from './alert-criteria.generator';
import { AppInstanceAlertCriteriaGenerator } from './app-instance-alert-criteria.generator';

export class AlertData {
    alertCriteriaData: AlertCriteriaData;
    type: 'Instance' | 'Install';
    sourceData: AppInstance<any> | EloquaInstall;
}

export interface AlertCriteriaData {
    timeDelayFrequency?: TimeDelay;
    emailAddresses?: string;
}

export class Alert {
    static readonly AppStatsResultStatusUpdated = new Alert(
        'Records marked as Errored',
        'recordsMarkedAsErrored',
        AlertConstants.Severity.STANDARD.code,
        AlertConstants.Analysis.AppStatsResultStatusUpdated,
        [AlertConstants.AnalysisCriteria.DEFAULT_APPSTATS_ERROR_STATUS],
        AlertConstants.EmailTemplate.AppStatsResultStatusUpdate,
        AlertConstants.AlertCategory.AppStats,
        false
    );
    static readonly SeiScoringRecordsStuckInProgress = new Alert(
        'Records with Potential Processing Issues',
        'seiScoringRecordsStuckInProgress',
        AlertConstants.Severity.STANDARD.code,
        AlertConstants.Analysis.SeiScoringRecordsStuckInProgress,
        [AlertConstants.AnalysisCriteria.DEFAULT],
        AlertConstants.EmailTemplate.SeiScoringRecordsInProgress,
        AlertConstants.AlertCategory.AuxDb,
        true
    );
    static readonly TFSTalkingPointsRecordsStuckInProgress = new Alert(
        'Records with Potential Processing Issues',
        'tfsTalkingPointsRecordsStuckInProgress',
        AlertConstants.Severity.STANDARD.code,
        AlertConstants.Analysis.AppStatsResultStatusStuckInProgress,
        [AlertConstants.AnalysisCriteria.DEFAULT],
        AlertConstants.EmailTemplate.TFSTalkingPointsRecordsInProgress,
        AlertConstants.AlertCategory.AppStats,
        true
    );

    static readonly TFSTalkingPointsResultStatusUpdated = new Alert(
        'Records marked as Errored',
        'recordsMarkedAsErrored',
        AlertConstants.Severity.STANDARD.code,
        AlertConstants.Analysis.AppStatsResultStatusUpdated,
        [AlertConstants.AnalysisCriteria.DEFAULT_TFS_TP_ERROR_STATUS],
        AlertConstants.EmailTemplate.TFSTalkingPointsResultStatusUpdate,
        AlertConstants.AlertCategory.AppStats,
        false
    );

    static readonly ExpiredEloquaOauth = new Alert(
        'Expired Eloqua Oauth',
        'expiredEloquaOauth',
        AlertConstants.Severity.STANDARD.code,
        AlertConstants.Analysis.ExpiredEloquaOauth,
        [AlertConstants.AnalysisCriteria.DEFAULT_EXPIRED_ELOQUA_OAUTH],
        AlertConstants.EmailTemplate.ExpiredEloquaOauth,
        AlertConstants.AlertCategory.Oauth,
        false,
        1
    );

    static DEFAULT_GENERATOR: AlertCriteriaGenerator<AppInstance<any>> = new AppInstanceAlertCriteriaGenerator();

    private constructor(
        public readonly label: string,
        public readonly property: string,
        public readonly severity: number,
        public readonly analysis: string,
        public readonly analysisCriteria: AnalysisCriteria[],
        public readonly emailtemplateName: string,
        public readonly alertCategory: string,
        public readonly includeTimeDelay: boolean,
        public readonly maximumPerDay?: number
    ) {}

    public createAlertCriteria<T>(
        data: T,
        globalId: string,
        timeDelay?: TimeDelay,
        generator?: AlertCriteriaGenerator<T>
    ): AlertCriteria {
        if (generator) {
            return generator.generate(this, data, globalId, timeDelay);
        }
        return Alert.DEFAULT_GENERATOR.generate(this, data, globalId, timeDelay);
    }

    public copy(): Alert {
        return Object.assign({}, this);
    }
}

export interface AnalysisCriteria {}

export class FlexibleAnalysisCriteria implements AnalysisCriteria {
    property: string;
    operator: string;
    values: string[];
}
