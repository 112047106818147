import { TimeUnits } from '../../../constants/time.constants';
import { NucleusObject } from '../nucleus.model';
import { AnalysisCriteria } from '../../alerts/index';

export class AlertCriteria extends NucleusObject {
    active: boolean;
    accountId: number;
    eloquainstallId?: number;
    appId?: number;
    appinstanceId?: number;
    alertCategory: string;
    emailtemplateId?: number;
    emailtemplateName?: string;
    severity: number;
    analysis?: string;
    analysisCriteria?: AnalysisCriteria = {};
    maximumPerDay?: number;
    futureAlertUnit?: TimeUnits;
    futureAlertInterval?: number;
}