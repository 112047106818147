export class HttpHeaderConstants {
    static defaultHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    static mergePatch = { 'Content-Type': 'application/merge-patch+json' };
    static skipInterceptor = { 'Skip-Interceptor': 'true' };
    static noCaching = { 'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0' };
}
