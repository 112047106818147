import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';

import { CloudUserService } from 'projects/internal/r1-component-library/src/lib/services/appcloud/cloud-user.service';
import { UpdateCloudUserData } from '../../states/actions/cloud-user.actions';
import { AuthenticationService } from 'projects/internal/r1-component-library/src/lib/services/appcloud';
import { AuthenticationPayload } from 'projects/internal/r1-component-library/src/lib/models/oauth-token.model';
import { NavLinkGrouping } from 'projects/internal/r1-component-library/src/lib/components/sidenav/models/nav-link.model';

@Component({
    selector: 'r1-home',
    template: `
        <r1-admin-header [userPayload]="userPayload"></r1-admin-header>
        <r1-sidenav [sidenavLinksGroupingArray]="linkGroups" [initialOpenState]="true">
            <r1-welcome [userPayload]="userPayload" *ngIf="currentRoute === '/admin/metrics'"></r1-welcome>
            <router-outlet></router-outlet>
        </r1-sidenav>
        <r1-footer></r1-footer>
    `,
    styles: []
})
export class HomeComponent implements OnInit {
    currentRoute: string = '';
    public userPayload: AuthenticationPayload = null;

    homeLinks: NavLinkGrouping = {
        subheaderTitle: '',
        links: [{ name: 'Home', faIcon: ['fas', 'home'], path: 'metrics' }]
    };

    dashboardLinks: NavLinkGrouping = {
        subheaderTitle: 'Dashboard',
        links: [
            { name: 'Accounts and Users', faIcon: ['fas', 'users'], path: 'dashboard/accounts-and-users' },
            { name: 'Licenses', faIcon: ['fas', 'funnel-dollar'], path: 'dashboard/licenses' },
            { name: 'App Usage', faIcon: ['fas', 'chart-area'], path: 'dashboard/app-usage' }
        ]
    };

    toolsLinks: NavLinkGrouping = {
        subheaderTitle: 'Tools',
        links: [
            { name: 'Accounts', faIcon: ['fas', 'building'], path: 'tools/accounts' },
            { name: 'Users', faIcon: ['fas', 'user'], path: 'tools/users' },
            { name: 'Licenses', faIcon: ['fas', 'shopping-cart'], path: 'tools/licenses' },
            { name: 'Packages', faIcon: ['fas', 'box-full'], path: 'tools/packages' },
            { name: 'Apps', faIcon: ['fas', 'plug'], path: 'tools/apps' },
            { name: 'App Instances', faIcon: ['fas', 'tasks'], path: 'tools/app-instances' },
            { name: 'Notifications', faIcon: ['fas', 'megaphone'], path: 'tools/notifications' }
        ]
    };

    linkGroups: Array<NavLinkGrouping> = [this.homeLinks, this.dashboardLinks, this.toolsLinks];

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private cloudUserService: CloudUserService,
        private store: Store
    ) {
        this.router.events.subscribe((event: NavigationEvent) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
            }
        });
    }

    ngOnInit() {
        this.userPayload = this.authenticationService.getAccessTokenPayload();
        this.cloudUserService.getCloudUserById(this.userPayload.clouduserId).subscribe((cloudUser) => {
            this.store.dispatch(new UpdateCloudUserData(cloudUser));
        });
    }
}
