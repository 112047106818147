import { Component, Input } from '@angular/core';
import { AuthenticationPayload } from 'projects/internal/r1-component-library/src/lib/models/oauth-token.model';
import { faMapMarked, faInfoCircle, faBars } from '@fortawesome/pro-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'r1-admin-header',
    template: `
        <div class="header">
            <img src="assets/R1_logo.svg" id="r1-logo" routerLink="./" />
            <div id="header-icons-group">
                <a href="https://docs.relationshipone.com/docs" target="_blank">
                    <fa-icon [icon]="['fas', 'map-marked']" class="fa-lg fa-fw" matTooltip="Documentation"></fa-icon>
                </a>
                <a href="https://support.relationshipone.com/support/home" target="_blank">
                    <fa-icon [icon]="['fas', 'info-circle']" class="fa-lg fa-fw" matTooltip="Support"></fa-icon>
                </a>
                <r1-user-menu [userPayload]="userPayload"></r1-user-menu>
            </div>
        </div>
    `,
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() userPayload!: AuthenticationPayload;

    constructor(library: FaIconLibrary) {
        library.addIcons(faMapMarked, faInfoCircle, faBars);
    }
}
