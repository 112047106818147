import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'projects/internal/r1-component-library/src/lib/environments/environment';

import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CloudUserState } from './components/states/cloud-user.state';
import { CookieService } from 'ngx-cookie-service';
import { PublicApiInterceptor } from 'projects/internal/r1-component-library/src/lib/interceptors';

import { AppComponent } from './app.component';
import { SidenavService } from 'projects/internal/r1-component-library/src/lib/components/sidenav/services/sidenav.service';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountManagerState } from './components/states/account-paging.state';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxsModule.forRoot([CloudUserState, AccountManagerState], { developmentMode: !environment.production }),
        NgxsStoragePluginModule.forRoot({
            storage: StorageOption.SessionStorage
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        BrowserModule,
        ComponentsModule,
        MaterialModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        RouterModule
    ],
    providers: [
        HttpClientModule,
        CookieService,
        SidenavService,
        { provide: HTTP_INTERCEPTORS, useClass: PublicApiInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
