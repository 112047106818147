import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NameCountUtilsService {
    constructor() {}
    private MRRQueryURL: string = environment.publicApiHost + '/appcloud/applicenses/';

    makeParams(operator: any, createdAtDate: any, ownershipOperator: string, ownershipValue: string): string {
        let query = '';

        query += 'createdAtSearch[0].operator=' + encodeURIComponent(operator) + '&';
        query += 'createdAtSearch[0].value=' + encodeURIComponent(createdAtDate) + '&';

        if (ownershipOperator == 'regexMatch') {
            query += 'usernameSearch[0].operator=' + encodeURIComponent(ownershipOperator) + '&';
            query += 'usernameSearch[0].value=' + encodeURIComponent(ownershipValue) + '&';
        } else if (ownershipOperator == 'ownedByR1') {
            query += encodeURIComponent(ownershipOperator) + '=' + ownershipValue + '&';
        }

        return query;
    }

    makeMRRQuery(monthStart: string, monthEnd: string) {
        let query = this.MRRQueryURL + 'aggregates';

        query += '?ownedByR1=false' + '&' + 'queryScope=all' + '&field=mrr' + '&operation=sum' + '&';

        query += 'usageEndDateSearch[0].operator=' + encodeURIComponent('>=') + '&';
        query += 'usageEndDateSearch[0].value=' + encodeURIComponent(monthStart) + '&';

        query += 'usageStartDateSearch[0].operator=' + encodeURIComponent('<=') + '&';
        query += 'usageStartDateSearch[0].value=' + encodeURIComponent(monthEnd);

        return query;
    }

    makeYTD_MRRQuery() {
        let query = this.MRRQueryURL + 'mrrs';
        query += '?queryScope=all' + '&ownedByR1=false' + '&timeRange=yearToDate';
        return query;
    }
}
