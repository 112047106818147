import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IsAuthorizedGuard } from '../../is-authorized.guard';
import { LoggedInGuard } from '../../logged-in.guard';

import { AppcloudAdminComponent } from './appcloud-admin.component';
import { MetricsDashboardComponent } from './home/metrics-dashboard/metrics-dashboard.component';
import { AccountsListComponent } from './tools/accounts-list/accounts-list.component';
import { NotFoundComponent } from '../not-found/not-found.component';

const routes: Routes = [
    {
        path: 'admin',
        component: AppcloudAdminComponent,
        canActivate: [LoggedInGuard, IsAuthorizedGuard],
        canActivateChild: [LoggedInGuard, IsAuthorizedGuard],
        children: [
            { path: 'metrics', component: MetricsDashboardComponent },
            {
                path: 'tools',
                children: [{ path: 'accounts', component: AccountsListComponent }]
            },
            {
                path: '**',
                component: NotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppcloudAdminRoutingModule {}
