import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpEvent
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of, iif } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { catchError, switchMap, finalize, filter, take, retry, map, retryWhen, concatMap, delay } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { OauthToken } from '../models';
import { AuthenticationService } from '../services/appcloud';
import { AuthenticationLevelService } from '../services/appcloud/authentication-level.service';
import { InterceptorConstants } from '../constants';

@Injectable()
export class PublicApiInterceptor implements HttpInterceptor {
    private publicApiHost: string = environment.publicApiHost;
    private refreshInProgress: boolean = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private cookieService: CookieService,
        private authenticationService: AuthenticationService,
        private authenticationLevelService: AuthenticationLevelService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.shouldHandleRefresh(req)) {
            const authToken = this.getAuthToken();
            const refreshToken = this.getRefreshToken();

            return next.handle(this.addTokenToHeader(req, authToken)).pipe(
                map(response => {
                    if (response['status'] === 202) {
                        throw 202;
                    }
                    return response;
                }),
                retryWhen(response => {
                    return response.pipe(
                        concatMap((err, i) => {
                            if (err !== 202) {
                                return throwError(err);
                            }
                            return iif(
                                () => i >= 600,
                                throwError(new Error('Operation timed out after 10 minutes.')),
                                of(err).pipe(delay(1000))
                            );
                        })
                    );
                }),
                catchError((err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401 && err.error.error === 'invalid_token') {
                            if (this.refreshFails(err)) {
                                this.authenticationService.logout();
                                return of<HttpResponse<any>>();
                            } else {
                                return this.handle401Error(req, next, refreshToken);
                            }
                        } else if (
                            err.status === 401 &&
                            err.error.message === 'JWT must include accountId in the claims.'
                        ) {
                            this.authenticationService.logout();
                            return of<HttpResponse<any>>();
                        } else {
                            return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                })
            );
        } else {
            return next.handle(req);
        }
    }

    private refreshFails(err: HttpErrorResponse): boolean {
        return err.error.error_description.includes(InterceptorConstants.invalidRefreshToken) ||
        err.error.error_description.includes(InterceptorConstants.cannotConvertAccessToken) ||
        err.error.error_description.includes(InterceptorConstants.encodedTokenIsRefreshToken);
    }

    private getAuthToken(): string {
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            return this.cookieService.get('jwt-install-access');
        } else {
            return this.cookieService.get('jwt-access');
        }
    }

    private getRefreshToken(): string {
        if (this.authenticationLevelService.getAuthorizationLevel() === 'install') {
            return this.cookieService.get('jwt-install-refresh');
        } else {
            return this.cookieService.get('jwt-refresh');
        }
    }

    private shouldHandleRefresh(req: HttpRequest<any>): boolean {
        if (req.headers.has('Skip-Interceptor')) {
            return false;
        } else if (req.url.startsWith(this.publicApiHost)) {
            const hasInstallAuthToken = this.cookieService.check('jwt-install-access');
            const hasInstallRefreshToken = this.cookieService.check('jwt-install-refresh');
            const hasAuthToken = this.cookieService.check('jwt-access');
            const hasRefreshToken = this.cookieService.check('jwt-refresh');
            return (hasAuthToken && hasRefreshToken) || (hasInstallAuthToken && hasInstallRefreshToken);
        }
        return false;
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler, refreshToken: string): Observable<HttpEvent<any>> {
        if (!this.refreshInProgress) {
            this.refreshInProgress = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken(refreshToken).pipe(
                switchMap((newTokenObj: OauthToken) => {
                    if (newTokenObj.access_token) {
                        this.refreshTokenSubject.next(newTokenObj.access_token);
                        return next.handle(this.addTokenToHeader(req, newTokenObj.access_token));
                    } else {
                        this.authenticationService.logout();
                        return of<HttpResponse<any>>();
                    }
                }),
                catchError(() => {
                    this.authenticationService.logout();
                    return of<HttpResponse<any>>();
                }),
                finalize(() => {
                    this.refreshInProgress = false;
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToHeader(req, token));
                })
            );
        }
    }

    private addTokenToHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({
            headers: req.headers.append('Authorization', 'Bearer ' + token)
        });
    }
}
