import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Redirect } from "../../models/salesforce/redirect.model";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { catchError, finalize } from 'rxjs/operators';
import { ErrorHandlingService } from '../error-handling/error-handling.service';
import { Oauth } from '../../models/appcloud/index';
import { HttpHeaderConstants } from '../http-header.constant';
import { SalesforceTokenIntrospectionResponse } from "../../models/salesforce/salesforce-token-introspection-response.model";
import { SpinnerService } from "../spinner/spinner.service";

const headers = new HttpHeaders({
    'Content-Type': 'application/json'
});

@Injectable({
    providedIn: 'root'
})
export class SalesforceAuthorizationService {
    private OAUTH_URL: string = environment.publicApiHost + '/salesforce/oauth2';

    constructor(
        private errorHandlingService: ErrorHandlingService,
        private http: HttpClient,
        private spinnerService: SpinnerService
    ) {}

    authorize(installId: string, oauthId: number, redirectUri: string, useSandbox: boolean): Observable<Redirect> {
        const customHttpOptions = {
            headers: headers,
            params: new HttpParams({
                fromObject: {
                    installId: installId,
                    oauthId: oauthId.toString(),
                    redirectUri: redirectUri,
                    isTest: useSandbox.toString()
                }
            })
        };

        return this.http.get<Redirect>(this.OAUTH_URL + '/authorize', customHttpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while redirecting to Salesforce. Please try again later.'
                );
                return of<Redirect>();
            })
        );
    }

    refresh(oauthId: number, errorHandlingBehavior?: Function, additionalHeaders: any = {}): Observable<Oauth> {
        this.spinnerService.addProcess('SalesforceAuthorizationService.refresh', 'Refreshing Salesforce oauth...');
        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams().set('oauthId', oauthId.toString())
        };

        return this.http.get<Oauth>(this.OAUTH_URL + '/refresh', httpOptions).pipe(
            catchError((error) => {
                if (errorHandlingBehavior) {
                    return errorHandlingBehavior(error);
                } else {
                    this.errorHandlingService.handleHttpError(
                        error,
                        false,
                        'An error occurred while trying to refresh the Salesforce oauth token. Please try again later.'
                    );
                    return of<Oauth>();
                }
            }),
            finalize(() => this.spinnerService.completeProcess('SalesforceAuthorizationService.refresh'))
        );
    }

    introspectToken(oauthId: number, additionalHeaders: any = {}): Observable<SalesforceTokenIntrospectionResponse> {
        this.spinnerService.addProcess('SalesforceAuthorizationService.introspectToken', 'Inspecting Salesforce oauth...');
        const httpOptions = {
            headers: new HttpHeaders({ ...HttpHeaderConstants.defaultHeaders, ...additionalHeaders }),
            params: new HttpParams().set('oauthId', oauthId.toString())
        };

        return this.http.get<SalesforceTokenIntrospectionResponse>(this.OAUTH_URL + '/introspect', httpOptions).pipe(
            catchError((error) => {
                this.errorHandlingService.handleHttpError(
                    error,
                    false,
                    'An error occurred while trying to inspect the Salesforce oauth token. Please try again later.'
                );
                return of<SalesforceTokenIntrospectionResponse>();
            }),
            finalize(() => this.spinnerService.completeProcess('SalesforceAuthorizationService.introspectToken'))
        );
    }
}